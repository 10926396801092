:root {
    /*************************************/
    /********** color variable ***********/
    --h-yellow: rgb(255, 210, 70);
    --h-dark-yellow: rgb(255, 185, 0);
    --h-light-coral: rgb(255, 168, 168);
    --h-coral: rgb(255, 135, 135);
    --h-coral-hover: rgb(255, 107, 107);
    --h-dark-coral: rgb(250, 82, 82);
    --h-dark-coral-hover: rgb(240, 62, 62);
    --h-purple: rgb(225, 205, 255);
    --h-dark-purple: rgb(195, 175, 255);
    --h-dark-purple-hover: rgb(170, 150, 255);
    --h-deep-dark-purple: rgb(140, 100, 255);
    --h-green: rgb(100, 200, 0);
    --h-dark-green: rgb(50, 150, 0);
    --h-neon-green: rgb(125, 255, 0);
    --h-neon-green-hover: rgb(105, 235, 0);
    --h-blue: rgb(75, 145, 215);
    --h-blue-hover: rgb(55, 125, 195);
    --h-dark-blue: rgb(45, 115, 185);
    --h-deep-dark-blue: rgb(15, 85, 155);
    --h-light-grey: rgb(235, 235, 235);
    --h-grey: rgb(215, 215, 215);
    --h-grey-hover: rgb(195, 195, 195);
    --h-dark-grey: rgb(165, 165, 165);
    --h-dark-grey-hover: rgb(145, 145, 145);
    --h-deep-dark-grey: rgb(115, 115, 115);
    --h-main-blue: #2874ff;
    --h-main-blue-hover: #1164ff;
    --h-main-grey: #3A3A3A;
    --h-main-grey-hover: #202020;
    /*************************************/
    /*********** font variable ***********/
    --h-font-sans-serif: system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --h-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --h-body-font-family: var(--h-font-sans-serif);
    /*************************************/
    /*********** icon variable ***********/
    --h-icon-arrow: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%234f4f4f%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    --h-icon-touch-lock: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='rgba(255, 213, 2, 2)'%3E%3Cpath d='M18 8h-1L17 6c0 -2.76 -2.24 -5 -5 -5S7 3.24 7 6v2L6 8c-1.1 0 -2 0.9 -2 2v10c0 1.1 0.9 2 2 2h12c1.1 0 2 -0.9 2 -2L20 10c0 -1.1 -0.9 -2 -2 -2zM12 17c-1.1 0 -2 -0.9 -2 -2s0.9 -2 2 -2 2 0.9 2 2 -0.9 2 -2 2zM15.1 8L8.9 8L8.9 6c0 -1.71 1.39 -3.1 3.1 -3.1 1.71 0 3.1 1.39 3.1 3.1v2z'%3E%3C/path%3E%3C/svg%3E");
    --h-icon-touch-lock2: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='rgba(255, 213, 2, 2)'%3E%3Cpath d='M376 186h-20v-40c0-55-45-100-100-100S156 91 156 146v40h-20c-22.002 0-40 17.998-40 40v200c0 22.002 17.998 40 40 40h240c22.002 0 40-17.998 40-40V226c0-22.002-17.998-40-40-40zM256 368c-22.002 0-40-17.998-40-40s17.998-40 40-40 40 17.998 40 40-17.998 40-40 40zm62.002-182H193.998v-40c0-34.004 28.003-62.002 62.002-62.002 34.004 0 62.002 27.998 62.002 62.002v40z'%3E%3C/path%3E%3C/svg%3E");
    /*************************************/
}

.clearfix:after {
    display: table;
    content: " ";
    clear: both;
}

* html .clearfix {
    zoom: 1;
}
*:first-child+html .clearfix {
    zoom: 1;
}

a, link {
    color: inherit !important;
    text-decoration: none !important;
}

* {
    box-sizing: inherit;
}

#touchLockGuard {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    visibility: hidden;
    z-index: unset;
    padding-left: 5px;
}
.img-touchLock {
    width: 192px;
    height: 192px;
    background-image: var(--h-icon-touch-lock2);
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8;
}

.chatTouchLockGuard {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: var(--h-grey);
    opacity: 0.8;
    visibility: hidden;
}

.web-whisper-modal {
    display: flex;
    /* position: absolute;
    bottom: 2.1rem; */
    width: 100%;
    min-height: '2rem';
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: var(--h-grey);
    opacity: 0.8;
    visibility: hidden;
}
.web-whisper-modal.visible {
    visibility: visible;
}

select,
.button,
input[type="checkbox"],
input[type="radio"],
input:not(:disabled)~.custom-control-label {
    cursor: pointer;
}

select.custom-select {
    border-color: var(--h-light-grey);
}
select.custom-select:hover {
    border-color: var(--h-grey);
}
select.custom-select:focus {
    border-color: var(--h-dark-grey);
}

select.custom-select.none {
    padding: .375rem .75rem;
    background-image: unset;
}
select.custom-select.arrow-1 {
    background-size: .7rem;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23343a40' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z'%3E%3C/path%3E%3C/svg%3E");
}

.md-form {
    width: 100%;
    margin: 1rem 0 !important;
}

button {
    box-shadow: none !important;
}

button:focus,
button.focus,
.btn:focus,
.btn.focus {
    outline: none !important;
    box-shadow: none !important;
}

select:focus,
select:active {
    box-shadow: none !important;
}

select option {
    line-height: 1.5rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

button[type="basic"] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem .75rem;
    margin: .25rem;
    font-size: .75rem;
    font-weight: bolder;
    border: 1px solid var(--h-grey-hover);
    border-radius: .125rem;
    color: var(--h-deep-dark-grey);
    background: white;
    outline: none;
}
button[type="basic"]:hover {
    background: var(--h-light-grey);
}
button[type="basic"]:disabled {
    color: var(--h-dark-grey) !important;
    background: var(--h-grey) !important;
    border-color: var(--h-grey) !important;
}

button[type="basic"].red {
    border-color: rgb(230, 50, 50);
    background: red;
    color: white;
}
button[type="basic"].red:hover {
    background: rgb(230, 50, 50);
}

button[type="basic"].hiclasstv-blue {
    border-color: var(--h-blue-hover);
    background: var(--h-blue);
    color: white;
}
button[type="basic"].hiclasstv-blue:hover {
    background: var(--h-blue-hover);
}

input:not(:-webkit-autofill),
select:not(:-webkit-autofill),
textarea:not(:-webkit-autofill) {
    animation-name: onautofillcancel;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /* appearance: menulist-button;
    height: auto;
    color: fieldtext !important;
    background-image: none !important;
    background-color: transparent !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    transition: background-color 5000s;
    -webkit-text-fill-color: fieldtext !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important; */
    -webkit-text-fill-color: var(--h-deep-dark-grey) !important;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    display: flex;
    width: 100%;
    padding: .25rem .5rem;
    margin: .25rem;
    font-size: .8rem;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--h-light-grey);
    color: var(--h-dark-grey);
    background: white;
}
input[type="text"]:hover {
    border-color: var(--h-grey);
}
input[type="text"]:focus {
    border-color: var(--h-dark-grey);
}
input[type="password"]:focus {
    outline: none;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder {
    color: var(--h-dark-grey);
}

input[type="text"].small {
    font-size: 75%;
}

input[type="text"].search {
    border: none;
    border-radius: .25rem;
    background: var(--h-light-grey);
}

input.web-search-input:focus {
    border-bottom-color: #007bff;
}

.input-form {
    margin-top: 0.725rem !important;
    margin-bottom: 0.725rem !important;
}
.input-form+.input-form {
    margin-bottom: 0.5rem !important;
}
.input-form>input[type="text"],
.input-form>input[type="email"],
.input-form>input[type="password"] {
    font-size: .75rem;
}
.input-description {
    width: 90%;
    padding: 0rem 0.75rem;
    font-size: .625rem;
    color: var(--h-dark-grey);
    /* line-height: 1.375; */
}
.input-description.pentalk {
    width: 95%;
    padding: 0rem 0.125rem;
    line-height: 1.375;
    color: var(--h-dark-grey-hover);
}
.input-description.info{
    color: var(--h-dark-grey-hover);
}
.input-description.notice {
    padding-top: .5rem;
    color: var(--h-deep-dark-grey);
}
.input-description.title {
    /* padding-bottom: .5rem; */
    padding-top: .5rem;
    padding-bottom: .25rem;
    font-size: .725rem;
    font-weight: bold !important;
    color: #212529;
}
.input-name {
    font-size: .625rem;
    color: var(--h-dark-grey);
    line-height: 1.375;
    /* line-height: normal; */
}
label.input-form-label.active {
    font-size: .725rem !important;
}
label.input-form-label {
    transition: transform .35s ease-in-out, color .25s ease-out !important;
    /* transition: transform .3s cubic-bezier(0.4, 0, 1, 1), color .25s ease-out !important; */
}
input[type=time]:not(.browser-default)+label.input-form-label,
input[type]:-webkit-autofill:not(.browser-default):not([type=search])+label.input-form-label,
input[type]:not(:-webkit-autofill):not(.browser-default):not([type="search"])+label.input-form-label {
    font-size: .8rem;
}
input[type]:not(:-webkit-autofill):not(.browser-default):not([type="search"])+label.input-form-label {
    transform: translate(.625rem, 12px);
}
input[type]:not(:-webkit-autofill):not(.browser-default):not([type="search"])+label.input-form-label.active {
    /* transform: translateY(-14px) scale(.8); */
    /* transform: translate(.25rem, -14px); */
    transform: translate(.5rem, calc(-14px + .25rem));
    margin-bottom: .25rem;
}
input[type=time]:not(.browser-default)+label.input-form-label.active,
input[type]:-webkit-autofill:not(.browser-default):not([type=search])+label.input-form-label.active {
    /* transform: translateY(-25px); */
    /* transform-origin: 0 0; */
    /* transform: translate(.25rem, -25px); */
    transform: translate(.5rem, calc(-25px + .25rem));
    margin-bottom: .25rem;
}
.input-form-control {
    padding-left: .625rem !important;
    margin-bottom: .25rem;
}
.md-form .form-control.input-form-control {
    margin-bottom: .25rem;
}

body {
    margin: 0;
    padding: 0;
    color: #202020;
    font-family: -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    cursor: default;
    overscroll-behavior-y: none;
    -webkit-overscroll-behavior-y: none;
}

.pay-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.mobile .pay-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem;
    overflow-y: scroll;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 500px;
}
.pay-container .main-table-area {
    margin: 1.5rem 0rem 0.75rem 0rem;
    width: fit-content;
    max-width: 100%;
}
.moble .pay-container .main-table-area {
    margin: 1.5rem 0rem 0.75rem 0rem;
    width: 100%;
    max-width: 100%;
}
.pay-container>div.title-area {
    display: flex;
    width: auto;
    height: auto;
    max-width: 80%;
}
.pay-container .web-policy-box {
    width: auto;
    max-width: 35rem;
}

.title-area>img {
    width: auto;
    height: auto;
    max-width: 3.5rem;
}
.title-area>img~div {
    margin-left: .5rem;
    max-width: calc(100% - 3.5rem);
}

.main-table-area caption {
    display: none;
}
.main-table-area input[type="radio"] {
    position: relative;
    top: 1.5px;
}
.main-table-area input[type="radio"]~label {
    margin: 0rem .25rem;
}
.main-table-area>table {
    border-top: 2px solid var(--h-grey-hover);
    border-bottom: 1px solid var(--h-grey-hover);
    width: auto;
    max-width: 100%;
}
.main-table-area>table tr {
    width: auto;
    max-width: 100%;
    border-bottom: 1px solid var(--h-grey-hover);
}
.main-table-area>table tr:last-child {
    border-bottom: none;
}
.main-table-area>table th {
    padding: 0.5rem 1rem;
    width: auto;
    max-width: 100%;
    border-right: 1px solid var(--h-grey-hover);
    background-color: #edf2ff;
}
.main-table-area>table td {
    width: auto;
    max-width: 100%;
    overflow: hidden;
}
.main-table-area>table td>span {
    padding: 0rem 0.5rem;
    display: inline-block;
    width: auto;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
}
.main-table-area>table td>div {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
    width: fit-content;
    max-width: 100%;
}

ul.mainPayType {
    margin: 0px;
    padding: 0 0 0.5rem 0;
    list-style: none;
    border-bottom: 1px solid var(--h-grey);
}
ul.mainPayType li {
    display: inline-block;
    text-align: left;
    font-size: 0.8rem;
    margin: 0rem 0.5rem;
    line-height: 0;
}
ul.mainPayType li:first-child {
    margin-left: .5rem;
}
ul.mainPayType li>input[type="radio"]~label {
    color: inherit;
    font-weight: normal;
}
ul.mainPayType li>input[type="radio"]:checked~label {
    color: var(--h-dark-blue);
    font-weight: bold;
}

.payContent {
    display: none;
    padding-top: 0.5rem;
}
.payContent.current {
    display: flex;
    padding-top: 0.5rem;
}
.payContent ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.payContent ul li {
    display: inline-block;
    text-align: left;
    font-size: 0.8rem;
    margin: 0rem 0.5rem;
    line-height: 0;
}
.payContent ul li>input[type="radio"]~label {
    color: inherit;
    font-weight: normal;
}
.payContent ul li>input[type="radio"]:checked~label {
    color: var(--h-dark-blue);
    font-weight: bold;
}
.payContent ul li:first-child {
    margin-left: .5rem;
}

.terms-area {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}
.terms-area input[type="checkbox"] {
    position: relative;
    top: 1.5px;
}
.terms-area input[type="checkbox"]~label {
    margin: 0 .5rem;
}

.btn-area {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: .5rem 0rem;
    margin-bottom: .8rem;
    width: 100%;
}
.btn-area button.btn {
    padding: 0.5rem 1.125rem;
    width: fit-content;
    min-width: max-content;
    font-size: smaller;
    font-weight: bold;
    border-radius: 1rem;
}

button.btn-success {
    color: #ffffff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.btn-success:hover {
    color: #ffffff;
    background-color: #218838 !important;
    border-color: #1e7e34 !important;
}
.btn-success.focus,
.btn-success:focus {
    color: #ffffff;
    background-color: #218838 !important;
    border-color: #1e7e34 !important;
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5) !important;
}
.btn-success.active,
.btn-success:active,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #1e7e34 !important;
    border-color: #1c7430 !important;
}
.btn-success.disabled,
.btn-success:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

button.btn-light {
    color: #212529;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
}
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
}
.btn-light.focus,
.btn-light:focus {
    color: #212529;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5) !important;
}
.btn-light.active,
.btn-light:active,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #dae0e5 !important;
    border-color: #d3d9df !important;
}
.btn-light.disabled,
.btn-light:disabled {
    opacity: .65 !important;
    color: #212529;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
}

.hiclasstv-text {
    font-size: 0.9rem;
}

.hiclasstv-text.sm {
    font-size: 0.8rem;
}

.hiclasstv-text.xlg {
    font-size: 2rem;
}

.hiclasstv-text.xxlg {
    font-size: 2.5rem;
}

.font-xxsm {
    font-size: .625rem !important;
}
.font-xsm {
    font-size: .7rem !important;
}
.font-sm.plus {
    font-size: .75rem !important;
}
.font-sm {
    font-size: .8rem !important;
}
.font-md {
    font-size: .9rem !important;
}
.font-md.ssplus {
    font-size: .95rem !important;
}
.font-md.splus {
    font-size: 1rem !important;
}
.font-md.plus {
    font-size: 1.1rem !important;
}
.font-ml {
    font-size: 1.2rem !important;
}
.font-ml.plus {
    font-size: 1.3rem !important;
}
.font-lg {
    font-size: 1.4rem !important;
}
.font-lg.plus {
    font-size: 1.75rem !important;
}
.font-xlg {
    font-size: 2rem;
}
.font-xxlg {
    font-size: 3rem;
}
.font-xxlg.oops {
    font-family: fantasy;
    font-weight: bold;
    font-size: 5rem;
}
.font-xxxlg {
    font-size: 4rem;
}

button.round,
.round {
    border-radius: 1rem;
}

.no-effect {
    outline: none !important;
    border: none;
    transition: .5s;
    -webkit-transition: .5s;
}

.transparent {
    background-color: transparent !important;
}

.no-transition {
    background-color: transparent;
    transition: 0s !important;
    -webkit-transition: 0s !important;
}

span.func-btn {
    display: flex;
    padding: .25rem;
    color: var(--h-dark-grey);
    font-size: 1.1rem;
    cursor: pointer;
}
span.func-btn:hover {
    color: var(--h-deep-dark-grey);
}

.hiclasstv-blue span.func-btn {
    color: var(--h-dark-blue);
}
.hiclasstv-blue span.func-btn:hover {
    color: var(--h-deep-dark-blue);
}

.hiclasstv-yellow span.func-btn {
    color: white;
}
.hiclasstv-yellow span.func-btn:hover {
    color: var(--h-dark-yellow);
}

.title-area.notify span.func-btn {
    color: white;
}
.title-area.notify span.func-btn:hover {
    color: var(--h-dark-yellow);
}

.datepicker {
    position: relative;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
}

input.datepicker-input {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    border: 1px solid var(--h-light-grey);
    border-radius: .25rem;
    cursor: pointer;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.datepicker-icon {
    position: absolute;
    display: flex;
    right: 0;
    padding: 0 .5rem;
}

.content-template-pentalk .datepicker-input {
    padding-right: .75rem;
}
.content-template-pentalk .datepicker-icon {
    visibility: hidden;
}

.timepicker {
    position: relative;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.timepicker-input {
    display: flex;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    justify-content: space-around;
    padding: .375rem .75rem;
    font-size: .8rem;
    line-height: 1.5;
    background-color: white;
    border: 1px solid var(--h-light-grey);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.timepicker-input .custom-select {
    height: unset;
    padding: 0 .25rem;
    font-size: .8rem;
    background: white;
    border: 1px solid white;
    border-radius: 0;
}
.timepicker-input .custom-select:focus {
    border-color: var(--h-grey);
}

.invalid .timepicker-input,
.invalid .datepicker-input {
    border-color: red !important;
}

/***********************************************************/
/***********************************************************/
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scroll-show::-webkit-scrollbar {
    width: auto;
    height: auto;
}
.scroll-show::-webkit-scrollbar-button {
    height: 0px;
}
.scroll-show::-webkit-scrollbar-track-piece {
    background-color: rgba(1, 1, 1, 0.1) !important;
}
.scroll-show::-webkit-scrollbar-thumb {
    background-color: rgba(1, 1, 1, 0.2) !important;
}

.scroll-show-active::-webkit-scrollbar {
    width: auto;
}
.scroll-show-active::-webkit-scrollbar-button {
    height: 0px;
}
.scroll-show-active::-webkit-scrollbar-track-piece {
    background-color: rgba(1, 1, 1, 0.1) !important;
}
.scroll-show-active::-webkit-scrollbar-thumb {
    background-color: var(--h-blue) !important;
}
.scroll-show-active.light::-webkit-scrollbar-thumb {
    background-color: rgba(75, 145, 215, 0.5) !important;
}

.scroll-hor-show::-webkit-scrollbar {
    height: auto;
}
.scroll-hor-show::-webkit-scrollbar-button {
    width: 0px;
    margin: 5px;
    padding: 5px;
}

/************* Template **************/

.alert-area {
    display: flex;
    width: 95%;
    justify-content: center;
    padding: .5rem .25rem;
    margin: 1rem 0 .5rem;
    border-radius: .5rem;
    text-align: center;
}

.main-template {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: white !important;
}
.main-template.web {
    width: unset;
    height: unset;
    min-height: unset;
}
.mobile .main-template {
    overflow: auto;
    min-height: calc(var(--vh, 1vh) * 100);
}

.main-container {
    min-width: 700px;
    height: 100%;
}

.outline {
    background-color: white;
    border: 1px solid !important;
}
.outline.bold {
    border-width: 2px !important;
}

.b-top {
    border-top: 1px solid var(--h-grey);
}
.b-none {
    border: none !important;
}

img.content-img {
    width: 100%;
}

.input-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: .5rem .25rem;
    background-color: white;
}
.input-area.transparent {
    background-color: transparent;
}

.input-label {
    display: flex;
    width: 45%;
    max-width: 120px;
    justify-content: flex-end;
    padding-right: .75rem;
}
.content-template-pentalk .input-label {
    width: unset;
}
.login-window .input-label {
    justify-content: space-around;
}

.input-label.medium {
    font-size: 85%;
}
.input-label.small {
    font-size: 70%;
}

.input-data {
    display: flex;
    width: -webkit-fill-available;
}
.input-data.medium {
    font-size: 85%;
}

.title-area {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.title-area.main {
    height: 3rem;
    min-height: 3rem;
    font-size: 1rem;
    padding: 0 .5rem;
}
.title-area.sub {
    justify-content: start;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: .25rem .5rem !important;
    font-size: .9rem;
    border: none;
}
.title-area.notify {
    justify-content: start;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: .25rem .5rem !important;
    line-height: unset;
    color: white;
    font-size: .8rem;
    border: none;
}

.title-area:not(.live) button {
    border: none !important;
    outline: none !important;
    background-color: transparent;
    transition: .5s;
    -webkit-transition: .5s;
}

.title-area.hiclasstv-blue button[type="button"]:not(.red):hover,
.title-area.hiclasstv-blue button[type="button"]:not(.red).hover {
    background-color: var(--h-blue-hover) !important;
}
.title-area.hiclasstv-blue button[type="button"]:not(.red).select {
    background-color: var(--h-dark-blue) !important;
}
.title-area.hiclasstv-blue button[type="button"]:not(.red).select:hover,
.title-area.hiclasstv-blue button[type="button"]:not(.red).select.hover {
    background-color: var(--h-deep-dark-blue) !important;
}

.title-area>*~.func-area {
    display: flex;
    justify-content: space-between;
}
.func-area.right {
    justify-content: unset;
}

.func-area button {
    display: flex;
    border-radius: 50%;
    padding: .5rem;
}
.func-area button:not(:last-child) {
    margin-right: .25rem;
}

.func-area .count {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    padding: 0 .25rem;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.9);
    color: white;
    font-size: 20%;
    font-weight: bold;
}

.title-area .func-area button.select {
    background-color: var(--h-main-blue-hover);
}

.text-area {
    display: contents;
    width: 100%;
}
.text-area.wmin-50 {
    min-width: 50%
}
.mobile .text-area {
    display: unset;
}
.content-template-pentalk .text-area,
.hiclasstv-modal.right-side.live .text-area {
    display: flex;
    min-width: 50%;
    align-items: center;
    justify-content: center;
}

.text-area.admin {
    display: flex;
    align-items: center;
}

.area-5 {
    width: 5% !important;
}
.area-10 {
    width: 10% !important;
}
.area-15 {
    width: 15% !important;
}
.area-20 {
    width: 20% !important;
}
.area-25 {
    width: 25% !important;
}
.area-30 {
    width: 30% !important;
}
.area-35 {
    width: 35% !important;
}
.area-40 {
    width: 40% !important;
}
.area-45 {
    width: 45% !important;
}
.area-50 {
    width: 50% !important;
}
.area-55 {
    width: 55% !important;
}
.area-60 {
    width: 60% !important;
}
.area-65 {
    width: 65% !important;
}
.area-70 {
    width: 70% !important;
}
.area-75 {
    width: 75% !important;
}
.area-80 {
    width: 80% !important;
}
.area-90 {
    width: 90% !important;
}
.area-95 {
    width: 95% !important;
}

.wmin-50 {
    min-width: 50% !important;
}

.fit-content {
    min-width: fit-content;
    max-width: fit-content;
}
.full {
    min-width: 40%;
    width: 100%;
}

/******** Modal Template ********/

.hiclasstv-modal {
    display: flex;
    position: fixed;
    left: 0;
    top: 3rem;
    width: 100%;
    height: calc(100% - 6.5rem) !important;
    justify-content: center;
    z-index: 5;
    overflow: auto;
    background-color: rgb(0, 0, 0, 0.4);
}
.hiclasstv-modal.right-side {
    justify-content: flex-end;
}
.hiclasstv-modal.right-side.chatting {
    top: 0;
    height: calc(100% - 3.5rem) !important;
}
.hiclasstv-modal.right-side.live {
    position: absolute;
    top: 0;
    height: 100% !important;
}
.hiclasstv-modal.centered {
    z-index: 1000;
    align-items: center;
}
.hiclasstv-modal.centered.chatting {
    top: 0;
    height: calc(100% - 3.5rem) !important;
}
.hiclasstv-modal.centered.web {
    z-index: 5;
    position: absolute !important;
    top: 0 !important;
    min-width: unset !important;
    height: 100% !important;
}
.hiclasstv-modal.bottomed {
    z-index: 7;
    justify-content: center;
    align-items: flex-end;
}
.hiclasstv-modal.all-covered {
    z-index: 10;
    top: 0;
    height: 100% !important;
    align-items: center;
}
.hiclasstv-modal-all-covered-alert {
    position: fixed;
    align-self: flex-start;
    width: 100% !important;
    height: 300px !important;
    overflow: auto;
    background-color: white !important;
    justify-content: center;
    align-items: start;
}

.trans-area {
    width: 100%;
    height: 100%;
    background: transparent;
}

.hiclasstv-modal>div:not(.trans-area),
.hiclasstv-modal>div.trans-area>div {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: .25rem;
    font-size: .8rem;
    overflow: auto;
    background-color: white;
    box-shadow: 0px 0px 20px #495057;
}
.hiclasstv-modal.right-side>div {
    width: 80%;
}
.hiclasstv-modal.centered>div,
.hiclasstv-modal.all-covered>div {
    width: 90%;
    align-items: center;
}
.hiclasstv-modal.all-covered>div.profile {
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: var(--h-grey);
}
.hiclasstv-modal.topped>div:not(.trans-area),
.hiclasstv-modal.topped>div.trans-area>div {
    width: 100%;
    height: max-content;
    align-items: center;
}

.hiclasstv-modal .profile>div {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}
/* .hiclasstv-modal .profile>div:last-child:not(.exception) {
    position: relative;
    top: unset;
} */

.hiclasstv-modal .profile button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: .5rem;
}
.hiclasstv-modal .profile button:hover {
    background-color: var(--h-grey-hover);
}

.card.grey-gradient-card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 100px 100px 0px inset, rgba(0, 0, 0, 0.12) 0px 2px 2px -2px inset;
}

.modal .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modal .card-body>div {
    width: 100%;
}

.modal .card .title-area {
    font-size: .9rem;
    margin-top: .5rem;
}
.mobile .modal .card .title-area {
    display: unset;
}

.title-area .title {
    display: flex;
    max-width: 40%;
}
.mobile .title-area .title {
    display: contents;
}
.title.wmax-20 {
    max-width: 20%;
}
.title.wmax-70 {
    max-width: 70%;
}

.modal .content-area,
.hiclasstv-modal .content-area,
.login-window .content-area {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    border-radius: .5rem;
    padding: 1rem 0;
    margin: 1rem 0;
    background: var(--h-grey);
}

.modal .content-area.transparent,
.hiclasstv-modal .content-area.transparent,
.login-window .content-area.transparent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: .5rem 0;
    margin: .5rem 0;
    background-color: transparent;
}

.hiclasstv-modal .title-area {
    padding: .5rem .25rem .725rem;
    border-bottom: 1px solid var(--h-light-grey);
}
.hiclasstv-modal .title-area.sub:not(.p-2) {
    padding-right: .25rem !important;
    border: none;
}

.hiclasstv-modal .footer-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: .725rem .25rem .5rem;
    border-top: 1px solid var(--h-light-grey);
}

.hiclasstv-modal .title-area>span.button {
    width: 30%;
    max-width: max-content;
    font-size: .8rem;
}
.hiclasstv-modal .title-area>button.button:hover,
.hiclasstv-modal .title-area>button.button.hover {
    background-color: var(--h-grey);
}

.dropdown-menu.profile {
    width: 400px;
    left: -90%;
    padding: .5rem;
    box-shadow: 0 2px 5px 0 rgb(0, 0, 0, .125), 0 2px 10px 0 rgb(0, 0, 0, .125);
}
.mobile .dropdown-menu.profile {
    width: 90%;
    left: 0;
}

/******** HiClass Pentalk CSS ********/

.hiclasstv-template {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 0.9rem;
    min-width: 320px;
    width: 100%;
    justify-content: left;
    align-items: top;
    background: #343a40 !important;
}

.hiclasstv-template .main-container {
    min-width: initial;
}

.content-template-pentalk {
    display: flex;
    flex-direction: column;
    margin: 0px 1px 0px 0px !important;
    width: 100% !important;
    height: calc(100% - 3rem) !important;
    max-height: calc(100% - 3rem) !important;
    overflow: hidden;
    background-color: white !important;
}
.content-template-pentalk .small {
    font-size: 90%;
}

.footer-template {
    margin: 0px 1px 0px 0px;
    width: 100%;
}
.footer-template a {
    min-width: 25%;
    width: 40%;
}

.footer-menu {
    display: flex;
    height: 3.5rem;
    justify-content: center;
    align-items: center;
    background-color: var(--h-main-grey);
    transition: .5s;
    -webkit-transition: .5s;
    cursor: pointer;
}
.footer-menu:hover {
    background-color: var(--h-main-grey-hover);
}
.footer-menu.active {
    background-color: #2874ff;
}
.footer-menu.active:hover {
    background-color: #1164ff;
}

button.footer-menu {
    border: none;
    outline: none;
    height: unset;
}

.footer-menu-icon {
    width: 20px;
    height: 20px;
}

/*********** Web site CSS ***********/

.main-template-web {
    justify-content: space-between;
    background: white !important;
}

.mobile .main-container {
    min-width: 100%;
}

.content-template-web {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 800px;
    height: max-content;
    min-height: 100%;
    overflow: auto;
    padding: 10px 0px;
}
.mobile .content-template-web {
    min-width: initial;
}

.footer-template-web {
    display: flex;
    padding: 1rem .5rem;
    min-height: 5rem;
    max-height: max-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top: 1px solid var(--h-grey);
    background-color: white;
    color: var(--h-dark-grey);
    font-size: .8rem;
    font-weight: 500;
    visibility: visible;
}

#header {
    position: relative;
    z-index: 1000;
}
#header.ani_fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    -webkit-animation: ani_dropdown .5s cubic-bezier(0.215, 0.610, 0.355, 1);
    animation: ani_dropdown .5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

@-webkit-keyframes ani_dropdown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes ani_dropdown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.header-menu {
    display: flex;
    width: 100%;
    /* background: linear-gradient(to right, rgba(69, 202, 252, 1.0), rgba(48, 63, 159, 1.0)); */
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .125), 0 2px 10px 0 rgba(0, 0, 0, .125); */
}

#header.ani_fixed .header-title {
    display: none;
}

#header.ani_fixed~.main-container {
    margin-top: 150px;
}

.header-info-block {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 700px;
    min-height: 50px;
    justify-content: center;
    padding-right: 0;
    background: linear-gradient(90deg, rgba(69, 202, 252, 1.0) 50%, rgba(48, 63, 159, 1.0) 50%);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .125), 0 2px 10px 0 rgba(0, 0, 0, .125);
}
.mobile .header-info-block {
    min-width: unset;
    /* background: unset; */
}

.header-info-menu {
    display: flex;
    width: 100%;
    max-width: 800px;
    height: 100%;
    justify-content: space-between;
    background: linear-gradient(to right, rgba(69, 202, 252, 1.0), rgba(48, 63, 159, 1.0));
}
.mobile .header-info-menu {
    /* max-width: unset; */
    position: relative;
    /* background: linear-gradient(to right, rgba(69, 202, 252, 1.0),rgba(48, 63, 159, 1.0)); */
}

.header-info-frame {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    z-index: 10;
}
.header-info-frame.left {
    left: 0;
    background: linear-gradient(to left, rgba(69, 202, 252, 0), rgba(69, 202, 252, 1.0));
}
.header-info-frame.right {
    clip-path: fill-box;
    background: linear-gradient(to right, rgba(48, 63, 159, 0), rgba(48, 63, 159, 1.0));
}

.header-info-button {
    display: flex;
    max-width: 250px;
    padding: 0px 5px;
    align-items: center;
    justify-content: center;
}
.mobile .header-info-button {
    z-index: 10;
}
.header-info-frame.right~.header-info-button {
    background-color: rgba(48, 63, 159, 1.0);
}

.header-big-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: max-content;
    z-index: 50;
    background-color: white;
}
#header.ani_fixed .header-big-menu {
    top: 0;
}

.scroll-wrap {
    overflow: hidden;
}

.scroll-wrap nav.horizontal {
    height: 100%;
    overflow-x: auto;
}
.scroll-wrap nav.vertical {
    overflow-y: auto;
}

nav.horizontal .nav_list {
    display: flex;
    height: 100%;
    min-width: 100%;
    width: max-content;
    padding: 0 8px;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
    transition-duration: 0ms;
    list-style: none;
    font-weight: 500;
}

nav.horizontal .nav_item {
    display: flex;
    position: relative;
    min-width: max-content;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0px 4px;
    padding: 0px 8px;
    cursor: pointer;
}

.menu-category {
    margin-right: 8px;
    border: 1.5px solid white;
    border-radius: .725rem;
}

.accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
}

.accordion-title {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: .5rem;
}

.accordion-content {
    display: flex;
    font-size: 1rem;
    line-height: 2rem;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.only-mobile {
    display: none;
    visibility: hidden;
}
.mobile .only-mobile {
    display: flex;
    visibility: visible;
}

.only-web {
    display: flex;
    visibility: visible;
}
.mobile .only-web {
    display: none;
    visibility: hidden;
}

.web-main {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 800px;
    min-height: max-content;
    margin: 10px 0px;
    z-index: 5;
    background-color: transparent;
}
.web-main.admin {
    max-width: 1000px;
}
.mobile .web-main {
    min-width: unset;
    width: 100%;
}
.mobile .web-main .card-body {
    padding: 1.25rem 0;
}

.mobile .web-main.list-area .card:not(.web-list-card) {
    box-shadow: unset;
    padding: 0 !important;
}

.info-circle {
    display: flex;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    color: white;
    background: rgb(63, 133, 203);
    border: 3px solid rgb(63, 133, 203);
    position: absolute;
    top: -2.5rem;
    align-items: center !important;
    justify-items: center !important;
}

.info-area {
    background: white;
}
.mobile .web-main.info-area {
    padding: 0 1rem;
}

.info-area.admin {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: .75rem;
    border: 2px solid var(--h-blue);
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--h-deep-dark-grey);
    background-color: var(--h-light-grey);
}

.line {
    display: flex;
    width: 100%;
    margin: .5rem 0;
}
.line .title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    padding-right: .5rem;
    margin: 0 .5rem;
    border-right: 2px solid var(--h-blue);
}
.line .value {
    display: flex;
    width: 75%;
}

.content-area .title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: .25rem 0;
    margin-bottom: .5rem;
    font-size: .9rem;
    border-bottom: 1px solid hsl(0, 0%, 50%);
}
.content-area .title > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.content-area .element {
    display: flex;
    width: 100%;
    min-height: 80px;
    padding: .5rem 0;
}
.content-area .element:not(~.add-btn-area) {
    border-bottom: 1px dashed grey;
}
.element~.element {
    border-top: 1px dashed lightgrey;
}
.content-area .element .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content-area .element .item.preview {
    min-height: 250px;
}
.content-area .element input[type="number"] {
    border-radius: 0;
    padding: .125rem .25rem;
    font-size: .9rem;
    border-width: 1px;
    height: 31px;
}

textarea.content-text {
    align-content: center;
    width: 100%;
    line-height: 1.8;
    height: 100%;
    font-size: .9rem;
    vertical-align: middle;
    /*border-color: var(--h-grey);*/
    background-color: transparent;
}
textarea.content-text.default {
    width: 90%;
}
textarea.content-text.read {
    border: none;
    cursor: pointer;
    pointer-events: none;
    resize: none;
}

button.remove-bt {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    background: red;
    border-radius: 1rem;
    border: 2px solid black;
    margin: 0;
    padding: .25rem;
}

.web-side-menu {
    display: flex;
    position: relative;
    height: max-content;
    z-index: 10;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, .125);
    -webkit-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.125);
    border-top-right-radius: 0 .125rem;
    border-bottom-right-radius: .125rem;
}
.mobile .web-side-menu {
    display: none;
    visibility: hidden;
}

.web-side-menu>ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 65px;
    overflow: hidden;
    border-radius: 0 .25rem .25rem 0;
    background-color: transparent !important;
    transition: .5s;
    -webkit-transition: .5s;
}
.web-side-menu>ul:hover,
.web-side-menu>ul.hover {
    width: 170px;
    cursor: pointer;
}

.web-side-menu>ul li {
    position: relative;
    display: flex;
    padding: .75rem 1.25rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: white;
    color: var(--h-blue);
    border-bottom: 1px solid var(--h-grey);
    border-right: 1px solid var(--h-grey);
}
.web-side-menu>ul li:first-child {
    border-top-right-radius: .25rem;
}
.web-side-menu>ul li:last-child {
    border-bottom-right-radius: .25rem;
}
.web-side-menu>ul li:hover {
    border-color: var(--h-grey-hover);
    background-color: var(--h-grey);
    color: white;
}

.web-side-menu>ul li.active {
    background-color: var(--h-blue);
    color: white;
    border-color: var(--h-dark-blue);
}
.web-side-menu>ul li.active:hover {
    background-color: var(--h-blue-hover);
}

.web-side-menu>ul li svg {
    transition: .3s;
    -webkit-transition: .3s;
}
.web-side-menu>ul li>span {
    transition: .3s;
    -webkit-transition: .3s;
    opacity: 0;
}
.web-side-menu>ul:hover li>span {
    opacity: 1;
}

.web-policy-box {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--h-grey);
    border-radius: .25rem;
    width: 600px;
    max-width: 100%;
    max-height: 500px;
    overflow: auto;
}
.mobile .web-policy-box {
    width: 100%;
    max-height: 280px;
}

.web-policy-box.no-modal {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}
.mobile .web-policy-box.no-modal {
    width: 100%;
    max-height: 280px;
}
.footer-template-web a {
    text-decoration: underline !important;
}

.web-policy-box h3 {
    font-size: .9rem;
    font-weight: bold !important;
}

.web-policy-box span {
    font-size: .8rem;
    line-height: 1.5rem;
}
.web-policy-box span.sub-title {
    font-weight: bold;
    margin: -.8rem;
}

.web-policy-box ul {
    list-style: none;
    padding-inline-start: 1.6rem;
}

.web-policy-box li {
    margin-bottom: 1rem;
}

.web-policy-box p {
    margin-bottom: .5rem;
}
.web-policy-box .last-p {
    margin-bottom: 2rem !important;
}

.web-policy-box .table td {
    border: solid 1px var(--h-deep-dark-grey) !important;
}

.web-search-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.web-search-option-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: .5rem 0;
}
.web-search-option-area:first-child {
    padding-bottom: 0;
}
.mobile .web-search-option-area {
    flex-direction: column;
}

.web-search-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    padding: 0 .5rem;
}
.mobile .web-search-option {
    width: 100%;
    padding: 0;
}

.web-search-select-label {
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding-right: .5rem;
}

.web-search-select-box {
    display: flex;
    width: 75%;
}

.web-toolbar-menu {
    display: flex;
    text-align: center;
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    align-items: center;
    justify-content: center;
}
.web-toolbar-menu.toolbar-menu-btn {
    width: 35%;
    min-width: 35%;
    max-width: 35%;
}

.web-toolbar-item {
    display: flex;
    width: 20%;
    margin: 0;
    padding: 0.5rem 0;
    font-size: 0.8rem;
    font-weight: bold;
    justify-content: center;
    color: var(--h-blue);
    border: 1px solid rgba(0, 0, 0, .125);
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: none;
    background-color: white;
}
.web-toolbar-item:hover {
    background-color: #dee2e6;
    color: white;
}
.web-toolbar-item:focus {
    outline: none;
}
.web-toolbar-item:first-child {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.web-toolbar-item:last-child {
    border-right-style: solid !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.web-toolbar-menu.toolbar-menu-date {
    padding: 0 .5rem;
    width: 40%;
    min-width: 40%;
    max-width: 40%;
}

.mobile .web-toolbar-menu {
    padding: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.mobile .toolbar-menu-date {
    padding: .5rem 0;
}

.info-title {
    font-size: 1rem;
    font-weight: bold;
}

table.quiz {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--h-light-grey);
  overflow: auto;
}

table.quiz tr {
  display: flex;
}
table.quiz tr.title {
  background-color: var(--h-blue);
  color: white;
}
table.quiz tr~tr {
  border-top: 1px dashed var(--h-light-grey);
}

table.quiz th {
  display: flex;
  align-items: center;
  padding: .375rem .25rem;
}
table.quiz th~th {
  border-left: 1px solid white;
}

table.quiz td {
  display: flex;
  align-items: center;
  padding: .375rem .25rem;
  font-size: .8rem;
}
table.quiz td.wrong {
  background-color: rgb(255, 55, 55);
  color: white;
  border-left-style: solid;
}
table.quiz td.noResponse {
    background-color: var(--h-light-grey);
    color: var(--h-deep-dark-grey);
}
table.quiz td~td {
  border-left: 1px dashed var(--h-light-grey);
}

.web-main table.table thead,
.web-main table.table-sm thead {
    color: var(--h-dark-grey);
}

thead:is(table.flex-list thead) {
    border-bottom: 1px solid #dee2e6;
}

table.table.flex-list tbody+tbody,
table.table-sm.flex-list tbody+tbody {
    border-top: 1px dashed var(--h-grey);
    border-bottom: none;
}

table.table.flex-list>tbody>tr+tr,
table.table-sm.flex-list>tbody>tr+tr {
    border-top: 1px dashed var(--h-grey);
    border-bottom: none;
}

table.table.flex-list>tbody>tr:is(:first-child)+tr:is(:last-child),
table.table-sm.flex-list>tbody>tr:is(:first-child)+tr:is(:last-child) {
    border-top: none;
    border-bottom: none;
}

table.table.flex-list.wlive-list>tbody>tr:not(:last-child),
table.table-sm.flex-list.wlive-list>tbody>tr:not(:last-child) {
    border-top: none;
    border-bottom: 1px dashed var(--h-grey);
}

table.table.flex-list.wlive-list>tbody>tr:is(:last-child),
table.table-sm.flex-list.wlive-list>tbody>tr:is(:last-child) {
    border-top: none;
    border-bottom: none;
}

/** 기존 css ---- 여기부터 */
/* table.table.flex-list tbody:not(:first-child)+tbody,
table.table-sm.flex-list tbody:not(:first-child)+tbody,
table.table.flex-list>tbody>tr+tr:not(:has(td table.sub-list)),
table.table-sm.flex-list>tbody>tr+tr:not(:has(td table.sub-list)) {
    border-top: 1px dashed var(--h-grey);
    border-bottom: none;
} */
/** 기존 css ---- 여기까지 */

table.table.flex-list th,
table.table-sm.flex-list th,
table.table.flex-list td,
table.table-sm.flex-list td {
    border-top: none;
    border-bottom: none;
}

table.table.flex-list th,
table.table-sm.flex-list th {
    vertical-align: middle;
    text-align: center;
    font-size: .9rem;
    padding: .6rem 0rem;
}

table.table.flex-list td {
    padding: .8rem 0rem;
}
table.table-sm.flex-list td {
    padding: .7rem 0rem;
}

/* table.flex-list td:has(table.sub-list) {
    padding: 0rem;
} */

thead:is(table.sub-list thead) {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(227, 242, 248);
    border-bottom: none;
}

table.table.sub-list tr,
table.table-sm.sub-list tr {
    border-bottom: 1px dashed var(--h-grey);
}
table.table.sub-list tr:last-child,
table.table-sm.sub-list tr:last-child,
table.table.sub-list tr:last-child>td,
table.table-sm.sub-list tr:last-child>td {
    border-bottom: none;
}

table.table.sub-list th,
table.table-sm.sub-list th,
table.table.sub-list td,
table.table-sm.sub-list td {
    border-top: none;
    border-bottom: none;
}
table.table.sub-list th,
table.table-sm.sub-list th {
    vertical-align: middle;
    text-align: center;
    font-size: .85rem;
    padding: .6rem 0rem;
}
table.table.sub-list.mediasoup th,
table.table-sm.sub-list.mediasoup th {
    font-size: .8rem;
    padding: .5rem 0rem;
}
table.table.sub-list td {
    padding-top: .75rem;
    padding-bottom: .75rem;
}
table.table-sm.sub-list td {
    padding-top: .65rem;
    padding-bottom: .65rem;
}
table.table-sm.sub-list.mediasoup td {
    vertical-align: middle;
    text-align: center;
    font-size: .75rem;
    /* padding: 0rem 0rem .25rem 0rem; */
    padding: 0rem;
}
/* table.table-sm.sub-list.mediasoup td {
    padding: 0rem 0rem .25rem 0rem;
} */
table.table-sm.sub-list.mediasoup td>button {
    margin: .5rem 0rem .5rem 0rem !important;
}
/* table.table-sm.sub-list.mediasoup td:has(div.mediasoup-div) {
    padding: 0rem 0rem .25rem 0 !important;
} */
table.table.sub-list.mediasoup th:not(:last-child),
table.table-sm.sub-list.mediasoup th:not(:last-child) {
    border-right: 1px dashed white;
}
table.table.sub-list.mediasoup td,
table.table-sm.sub-list.mediasoup td {
    border-left: 1px dashed var(--h-grey);
}

table.table-sm.sub-list.mediasoup th>div {
    border-top: 1px dashed white;
}
table.table-sm.sub-list.mediasoup th>div>span:not(:last-child) {
    border-right: 1px dashed white;
}
table.table-sm.sub-list.mediasoup td>span:not(:last-child) {
    border-right: 1px dashed var(--h-grey);
}

table.table-sm.sub-list table.table-sm.sub-list.mediasoup th {
    font-size: .75rem;
    font-weight: bolder;
    background-color: rgb(220, 235, 245);
}
table.table-sm.sub-list table.table-sm.sub-list.mediasoup td {
    font-size: .75rem;
}

table.table-hover tr.background-white:hover {
    background-color: white;
}

.mediasoup td>div.mediasoup-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

table.table-sm.sub-list table.table-sm.sub-list.mediasoup td {
    /* color: blue; */
    padding: .4rem 0rem;
}

div.mediasoup-div>div {
    display: flex;
    width: 100%;
}
div.mediasoup-div>div:first-child {
    font-weight: bolder;
    background-color: rgb(220, 235, 245);
}
div.mediasoup-div>div:not(:first-child) {
    height: 100%;
}
div.mediasoup-div>div:not(:first-child)>span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    height: auto;
}

div.mediasoup-div>div:first-child>span {
    padding: 0.5rem 0rem !important;
}
div.mediasoup-div>div:first-child>span:not(:last-child) {
    border-right: 1px dashed white;
}
div.mediasoup-div>div:not(:first-child)>span:not(:last-child) {
    border-right: 1px dashed var(--h-grey);
}

.mobile .pass-list td {
    font-size: .7rem;
}
.pass-list .pass-info-item {
    display: flex;
}
.mobile .pass-list .info-title {
    font-size: .8rem;
}

.web-list-title {
    text-align: center;
    width: 60%;
    max-width: 60%;
    min-width: 40%;
    vertical-align: middle;
}
.web-list-title.pchsPass {
    min-width: 60%;
    text-align: left;
}

.landscape-to-portrait {
    display: flex;
    justify-content: center;
}
.mobile .landscape-to-portrait {
    flex-direction: column;
}

.list-row {
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile .landscape-to-portrait .list-row {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    justify-content: unset;
}
.landscape-to-portrait .list-row .img,
.landscape-to-portrait .list-row .div {
    margin: 0 !important;
}

.mobile .landscape-to-portrait .btn {
    margin: 0 !important;
}
.mobile .landscape-to-portrait>.list-row~.list-row>.btn,
.mobile .landscape-to-portrait>.list-row~.list-row>.button {
    margin-top: .25rem !important;
}

.web-list-item {
    text-align: center;
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    align-items: center;
    justify-content: center;
}
.web-list-item.pchsPass {
    min-width: 40%;
    justify-content: space-between;
}
.mobile .web-list-item.pchsPass {
    justify-content: space-around;
}

.web-list-item .pass-use-date {
    width: 75%;
}
.mobile .web-list-item .pass-use-date {
    width: 100%;
}

.web-list-card {
    display: flex;
    align-items: center;
    width: 100%;
    /* max-width: 500px; */
    max-width: -webkit-fill-available;
    padding: 1rem;
}
.web-list-card.friend {
    width: unset;
    max-width: unset;
    padding: .5rem;
    justify-content: center;
    margin: .5rem 0;
}
.web-list-card .input-area {
    padding: 0;
    margin: .5rem;
}
.mobile .web-list-card {
    margin: 0 .5rem;
}
.mobile .web-list-card.friend {
    margin: .5rem;
}

.web-list-card-option {
    display: flex;
    width: 75%;
    justify-content: space-around;
}
.mobile .web-list-card-option {
    width: 100%;
}

.web-list-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.web-list-card-content .md-form {
    width: 75%;
}
.mobile .web-list-card-content .md-form {
    width: 100%;
    margin: .5rem 0;
}

.web-list-text {
    display: inline-block;
    width: 90%;
    max-width: max-content;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user-status {
    font-size: .8rem;
    text-align: center;
    vertical-align: center;
}
.mobile .user-status {
    font-size: .7rem;
}
.mobile .user-status.small-status {
    font-size: .6rem;
}

.live-status {
    padding: 0rem .25rem .125rem;
    border-radius: .25rem;
    color: white;
}

.main-grey-gradient {
    background: linear-gradient(180deg, #cccccc, white);
}
.r-blue-gradient {
    background: linear-gradient(40deg, #303f9f, #45cafc) !important;
}
.r-blue-gradient-rgba {
    background: linear-gradient(40deg, rgba(48, 63, 159, 0.9), rgba(69, 202, 252, 0.9)) !important
}

.list-group-hitem {
    position: relative;
    display: block;
    min-height: 4rem;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    background-color: #f8f9fa;
    border-top: 1px solid var(--h-main-grey);
}
.list-group-hitem.me {
    height: 4rem;
}
.list-group-hitem.member {
    min-height: unset;
    height: 3rem;
    background-color: white;
}
.list-group-hitem.member:last-child {
    border-bottom: none;
}
.list-group-hitem.broadcast {
    min-height: unset;
    height: 3rem;
}

.list-group-horizontal .list-group-hitem {
    display: flex;
    width: 25%;
    min-width: 25%;
    height: unset;
    padding: .25rem;
    margin: .25rem;
    font-size: .9rem;
    background-color: white;
    border: none;
}

.list-group-hitem-action {
    width: 100%;
    color: #212529;
    text-align: inherit;
}
.list-group-hitem-action:hover,
.list-group-hitem-action:focus {
    z-index: 1;
    color: #212529;
    text-decoration: none;
    background-color: #eaebec !important;
}
.list-group-hitem-action:active {
    color: #212529;
    background-color: #e9ecef !important;
}
.list-group-hitem:first-child {
    border-top: none;
}
.list-group-hitem:last-child {
    border-bottom: 1px solid var(--h-main-grey);
}
.list-group-hitem.active {
    z-index: 2;
    color: rgba(63, 133, 203, 0.9) !important;
    background-color: rgba(172, 200, 228, 0.9) !important;
    border-color: rgba(172, 200, 228, 0.9) !important;
}
.list-group-hitem+.list-group-hitem.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.hiclass-num-text {
    display: flex;
    align-self: center;
    text-align: center;
    word-break: break-all;
}

.hiclass-text {
    display: inline-block;
    width: 100%;
    max-width: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
}
.list-area .hiclass-text {
    text-align: center;
}
.hiclasstv-template .profile .hiclass-text {
    white-space: inherit;
}
.mobile .list-area .hiclass-text,
.mobile .modal .card .hiclass-text {
    display: contents;
    white-space: inherit;
    text-align: inherit;
    word-break: break-all;
}
.hiclass-text.area-50 {
    max-width: 50%;
}
.hiclass-text.area-70 {
    max-width: 70%;
}
.hiclass-text.area-90 {
    max-width: 90%;
}

.list-area .status {
    color: white;
    padding: 0 .25rem;
}

.hiclasstv-button {
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background .3s;
    -webkit-transition: background .3s;
}
.hiclasstv-button:hover {
    background-color: var(--h-grey) !important;
    opacity: 0.9;
    cursor: pointer;
}
.hiclasstv-button.nohover {
    background-color: transparent !important;
}
.hiclasstv-button.nohover:hover {
    background-color: transparent !important;
    opacity: 1.0 !important;
    cursor: pointer;
}

/************* color *************/

.live-status.onAir {
    border: 1px var(--h-green) solid;
    background-color: white;
    color: var(--h-green);
    font-weight: bold;
}
.live-status.on {
    font-size: .8rem;
    font-weight: bold;
    background-color: var(--h-green);
}
.live-status.off {
    font-size: .8rem;
    background-color: var(--h-dark-grey);
}

.user-status .login {
    font-weight: bold !important;
    color: green;
}
.user-status .logout {
    color: grey;
}
.user-status .studying {
    font-weight: bold !important;
    color: orange;
}

button.btn-outline-primary {
    background-color: white !important;
}
button.btn-outline-primary.bg-blue:hover {
    background-color: var(--h-blue) !important;
    color: white !important;
}

button.outline:not(.login, .logout) {
    background-color: white;
}
button.outline.login,
button.outline.logout {
    background: transparent !important;
    border-color: white !important;
    color: white !important;
}
button[type="button"].login:hover,
button[type="button"].login.hover {
    background-color: white !important;
    border-color: white !important;
    color: var(--h-blue) !important;
}
button[type="button"].logout:hover {
    background-color: red !important;
    border-color: red !important;
    color: white !important;
}

.outline-white {
    border-color: white !important;
}

.outline-red {
    border-color: red !important;
}

button.hiclasstv-yellow {
    background-color: var(--h-yellow) !important;
}
button.hiclasstv-yellow:hover,
button.hiclasstv-yellow.hover {
    background-color: var(--h-dark-yellow) !important;
}
.outline-hiclasstv-yellow {
    border-color: var(--h-yellow) !important;
}

.outline-hiclasstv-blue {
    border-color: var(--h-blue) !important;
    color: var(--h-blue) !important;
}
.outline-hiclasstv-blue:hover,
.outline-hiclasstv-blue.hover {
    background-color: var(--h-blue) !important;
    color: white !important;
}

.outline-hiclasstv-blue.active {
    background-color: var(--h-blue) !important;
    color: white !important;
}
.outline-hiclasstv-blue.active:hover,
.outline-hiclasstv-blue.active.hover {
    border-color: var(--h-blue-hover) !important;
    background-color: var(--h-blue-hover) !important;
    color: white !important;
}

.hiclasstv-btn {
    background-color: white !important;
    color: var(--h-blue) !important;
    transition: .3s;
    cursor: pointer;
}
.hiclasstv-btn:hover,
.hiclasstv-btn.hover {
    background-color: var(--h-grey) !important;
}
.hiclasstv-btn.active {
    background-color: var(--h-blue) !important;
    color: white !important;
}
.hiclasstv-btn.active:hover,
.hiclasstv-btn.active.hover {
    background-color: var(--h-blue-hover) !important;
}
.hiclasstv-btn:disabled {
    color: grey !important;
}

.hiclasstv-btn-transparent {
    background-color: transparent !important;
    border: none;
}
.hiclasstv-btn-transparent.hover,
.hiclasstv-btn-transparent:hover {
    background-color: transparent !important;
    border: none;
}
.hiclasstv-btn-transparent.focus,
.hiclasstv-btn-transparent:focus {
    border: none;
    outline: none;
}

.hiclasstv-btn-outline {
    color: rgba(63, 133, 203, 0.9);
    background-color: white;
    border: 2px solid rgb(63, 133, 203);
    outline: none !important;
}
.hiclasstv-btn-outline.active {
    color: white;
    background-color: rgb(63, 133, 203);
}

.hiclasstv-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*padding-top: 1rem;*/
    /*height: 2.25rem;*/
    /* margin: 38px 94px 38px 88px; */
    margin: 1.5rem 0;
    /*border-top: 1px solid var(--h-grey);*/
}
table .hiclasstv-page-wrapper {
    border: none;
    padding: unset;
}

.hiclasstv-page-btn {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    border: none;
    color: black;
    background-color: transparent;
    font-size: 1rem;
}
.hiclasstv-page-btn:hover {
    background-color: var(--h-light-grey);
}
.hiclasstv-page-btn[aria-current="page"] {
    background-color: var(--h-blue);
    color: white;
    font-weight: bold;
    cursor: revert;
    transform: revert;
}
.hiclasstv-page-btn:disabled {
    color: var(--h-grey) !important;
}
.hiclasstv-page-btn.move-to-previous,
.hiclasstv-page-btn.move-to-next {
    color: var(--h-dark-grey);
}
.hiclasstv-page-btn.move-to-first,
.hiclasstv-page-btn.move-to-last {
    width: 4rem;
    margin: 0;
    color: var(--h-deep-dark-grey);
}
.hiclasstv-page-btn.move-to-previous:hover,
.hiclasstv-page-btn.move-to-next:hover,
.hiclasstv-page-btn.move-to-first:hover,
.hiclasstv-page-btn.move-to-last:hover {
    transform: translateY(-2px);
    background-color: transparent;
}

.hiclasstv-blue {
    background-color: var(--h-blue) !important;
}
button.hiclasstv-blue:hover,
button.hiclasstv-blue.hover {
    background-color: var(--h-blue-hover) !important;
}

.hiclasstv-dark-blue {
    background-color: var(--h-dark-blue) !important;
}
button.hiclasstv-dark-blue:hover,
button.hiclasstv-dark-blue.hover {
    background-color: var(--h-deep-dark-blue) !important;
}

.hiclasstv-green {
    background-color: var(--h-neon-green) !important;
}
button.hiclasstv-green:hover,
button.hiclasstv-green.hover {
    background-color: var(--h-neon-green-hover) !important;
}

.hiclasstv-yellow {
    background-color: var(--h-yellow) !important;
}

.hiclasstv-lightgrey {
    background-color: #f8f9fa;
}

.hiclasstv-grey {
    background-color: var(--h-grey) !important;
}
button.hiclasstv-grey:hover,
button.hiclasstv-grey.hover {
    background: var(--h-grey-hover) !important;
}
.outline-hiclasstv-grey {
    border-color: var(--h-grey) !important;
}

.hiclasstv-darkgrey {
    background-color: var(--h-dark-grey) !important;
}
button.hiclasstv-darkgrey:hover,
button.hiclasstv-darkgrey.hover {
    background-color: var(--h-dark-grey-hover) !important;
}

.hiclasstv-main-grey {
    background-color: var(--h-main-grey) !important;
}
button.hiclasstv-main-grey:hover,
button.hiclasstv-main-grey.hover {
    background-color: var(--h-main-grey-hover) !important;
}

.hiclasstv-main-blue {
    background-color: var(--h-main-blue) !important;
}
button.hiclasstv-main-blue:hover,
button.hiclasstv-main-blue.hover {
    background-color: var(--h-main-blue-hover) !important;
}

.hiclasstv-lightcoral {
    background-color: var(--h-light-coral);
}
.hiclasstv-lightcoral:hover {
    background-color: var(--h-coral) !important;
}

.hiclasstv-dodgerblue {
    background-color: rgb(28, 162, 209);
}

.hiclasstv-cornflowerblue {
    background-color: cornflowerblue;
}

.hiclasstv-yellowgreen {
    background-color: rgb(108, 185, 54);
}

.hiclasstv-mediumseagreen {
    background-color: rgb(52, 182, 157);
}

.hiclasstv-red {
    background-color: crimson;
}

button.grey:hover,
button.grey.hover {
    background-color: var(--h-dark-grey) !important;
}
button.red:hover,
button.red.hover {
    background-color: rgb(230, 50, 50) !important;
}
button.green:hover,
button.green.hover {
    background-color: rgb(36, 126, 46) !important;
}

.hiclasstv-yellow-text {
    color: var(--h-yellow) !important;
}
.hiclasstv-violet-text {
    color: #b197fc !important;
}
.hiclasstv-coral-text {
    color: #fa5252 !important;
}
.hiclasstv-blue-text {
    color: var(--h-blue) !important;
}
.hiclasstv-darkblue-text {
    color: var(--h-dark-blue) !important;
}
.hiclasstv-deepDarkblue-text {
    color: var(--h-deep-dark-blue) !important;
}
.hiclasstv-grey-text {
    color: var(--h-grey) !important;
}
.hiclasstv-darkgrey-text {
    color: var(--h-dark-grey) !important;
}
.hiclasstv-deepDarkgrey-text {
    color: var(--h-deep-dark-grey) !important;
}
.hiclasstv-light-coral-text {
    color: var(--h-light-coral);
}
.hiclasstv-green-text {
    color: var(--h-neon-green-hover);
}

.hiclasstv-chatting-img {
    border: none;
    outline: none;
    border-radius: 20%;
    overflow: hidden;
}

.hiclasstv-chatting-img .member-profile {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.member-profile .member-3 {
    width: 60%;
    height: 60%;
}
.member-profile .member-3:first-child {
    width: 100%;
    height: 60%;
}

.hiclasstv-chatting-img .member-4 {
    width: 50%;
    height: 50%;
}

.hiclasstv-chatting-img .member-5 {
    width: 40%;
    height: 40%;
}

.hiclasstv-profile-img {
    border: 3px solid var(--h-dark-grey);
    outline: none;
    /* border-radius: 20%; */
    border-radius: 50%;
    overflow: hidden;
    margin-right: .5rem;
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    /* margin: 4px 1px 0px 1px; */
}
.hiclasstv-modal .profile .hiclasstv-profile-img {
    margin: 0;
}

#full-area {
    display: flex;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 4px;
}

.live-entire-area {
    position: relative;
    /* top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    padding: 4px; */
    width: 100%;
    height: 100%;
    height: calc(var(--vh, 1vh) * 100);
}

.live-layer-area {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 52px;
    align-items: center;
}

.live-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.mobile .portrait .live-area {
    bottom: unset;
    width: 100%;
    height: calc((100vw - 8px) * 9/16);
}
@media (orientation: portrait) {
    .mobile .ios .live-area {
        bottom: unset;
        width: 100%;
        height: calc((100vw - 8px) * 9/16);
    }
}

.live-area.mediasoup {
    display: flex;
    justify-content: flex-end;
    /* z-index: 45; */
}
@media (orientation: portrait) {
    .mobile .ios .live-area.mediasoup {
        left: unset;
        right: unset;
        width: -webkit-fill-available !important;
    }
}

.live-area.canvas {
    border: 1px solid var(--h-grey);
}

.live-area.canvas>div {
    position: relative;
    width: 100%;
    height: 100%;
    /*overflow-x: hidden;*/
    overflow-y: scroll;
    /*-webkit-overflow-scrolling: touch;*/
}
.live-area.canvas>div.draw {
    touch-action: none;
    /*overflow-y: scroll;
    -webkit-overflow-scrolling: auto;*/
}

/* canvas.draw-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
} */
canvas.draw-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
}
canvas.bg-canvas {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fffdf5;
}

.mediasoup_comp_container {
    position: absolute;
    top: 2px;
    background-color: white;
    /* right: 2px;
    width: 240px;
    height: 135px;
    padding-left: 2px;
    z-index: 5; */
}
.mediasoup_comp_container.main-view {
    left: 2px;
    right: 2px;
    bottom: 2px;
    padding-left: unset;
    z-index: unset;
    /* top: 2px;
    right: 2px;
    width: 240px;
    height: 135px;
    padding-left: 2px;
    z-index: 5; */
}
.mediasoup_comp_container.small-view-4 {
    left: unset;
    bottom: unset;
    width: 240px;
    height: 135px;
    padding-left: 2px;
    z-index: 5;
}
.small-view-4.view-1 {
    right: 722px;
}
.small-view-4.view-2 {
    right: 482px;
}
.small-view-4.view-3 {
    right: 242px;
}
.small-view-4.view-4 {
    right: 2px;
}
.mediasoup_comp_container.small-view-6 {
    left: unset;
    bottom: unset;
    width: 240px;
    height: 135px;
    padding-left: 2px;
    z-index: 5;
}
.small-view-6.view-1 {
    right: 482px;
}
.small-view-6.view-2 {
    right: 242px;
}
.small-view-6.view-3 {
    right: 2px;
}
.small-view-6.view-4 {
    top: 137px;
    right: 482px;
}
.small-view-6.view-5 {
    top: 137px;
    right: 242px;
}
.small-view-6.view-6 {
    top: 137px;
    right: 2px;
}
.mediasoup_comp_container.small-view-9 {
    left: unset;
    bottom: unset;
    width: 240px;
    height: 135px;
    padding-left: 2px;
    z-index: 5;
}
.small-view-9.view-1 {
    right: 482px;
}
.small-view-9.view-2 {
    right: 242px;
}
.small-view-9.view-3 {
    right: 2px;
}
.small-view-9.view-4 {
    top: 137px;
    right: 482px;
}
.small-view-9.view-5 {
    top: 137px;
    right: 242px;
}
.small-view-9.view-6 {
    top: 137px;
    right: 2px;
}
.small-view-9.view-7 {
    top: 272px;
    right: 482px;
}
.small-view-9.view-8 {
    top: 272px;
    right: 242px;
}
.small-view-9.view-9 {
    top: 272px;
    right: 2px;
}
.mediasoup_comp_container.multi-view-4 {
    width: calc(100% / 2);
    height: calc(100% / 2);
    padding-left: 0px;
}
.multi-view-4.view-1 {
    top: 0px;
    left: 0px;
    right: calc(calc(100% - 2px) / 2);
    /* top: 0px;
    left: calc(100vw / 2);
    right: unset; */
}
.multi-view-4.view-2 {
    top: 0px;
    left: calc(100% / 2);
    right: unset;
    /* top: 0px;
    left: 0px;
    right: calc(calc(100vw - 2px) / 2); */
}
.multi-view-4.view-3 {
    top: calc(100% / 2);
    left: 0px;
    right: calc(calc(100% - 2px) / 2);
    /* top: calc(100% / 2); */
    /* top: calc(calc(100vh - 44px) / 2) !important; */
    /* left: calc(100vw / 2);
    right: unset; */
}
.multi-view-4.view-4 {
    top: calc(100% / 2);
    left: calc(100% / 2);
    right: unset;
    /* top: calc(calc(100% - 44px) / 2);
    left: 0px;
    right: calc(calc(100vw - 2px) / 2); */
}
.mediasoup_comp_container.multi-view-6 {
    width: calc(100% / 3);
    height: calc(100% / 2);
    padding-left: 0px;
}
.multi-view-6.view-1 {
    top: 0px;
    left: 0px;
    right: calc(calc(100% - 2px) / 3);
}
.multi-view-6.view-2 {
    top: 0px;
    left: calc(100% / 3);
    right: unset;
}
.multi-view-6.view-3 {
    top: 0px;
    left: calc(calc(100% / 3) * 2);
    right: unset;
}
.multi-view-6.view-4 {
    top: calc(100% / 2);
    left: 0px;
    right: calc(calc(100% - 2px) / 3);
}
.multi-view-6.view-5 {
    top: calc(100% / 2);
    left: calc(100% / 3);
    right: unset;
}
.multi-view-6.view-6 {
    top: calc(100% / 2);
    left: calc(calc(100% / 3) * 2);
    right: unset;
}
.mediasoup_comp_container.multi-view-9 {
    width: calc(100% / 3);
    height: calc(100% / 3);
    padding-left: 0px;
}
.multi-view-9.view-1 {
    top: 0px;
    left: 0px;
    right: calc(calc(100% - 2px) / 3);
}
.multi-view-9.view-2 {
    top: 0px;
    left: calc(100% / 3);
    right: unset;
}
.multi-view-9.view-3 {
    top: 0px;
    left: calc(calc(100% / 3) * 2);
    right: unset;
}
.multi-view-9.view-4 {
    top: calc(100% / 3);
    left: 0px;
    right: calc(calc(100% - 2px) / 3);
}
.multi-view-9.view-5 {
    top: calc(100% / 3);
    left: calc(100% / 3);
    right: unset;
}
.multi-view-9.view-6 {
    top: calc(100% / 3);
    left: calc(calc(100% / 3) * 2);
    right: unset;
}
.multi-view-9.view-7 {
    top: calc(calc(100% / 3) * 2);
    left: 0px;
    right: calc(calc(100% - 2px) / 3);
}
.multi-view-9.view-8 {
    top: calc(calc(100% / 3) * 2);
    left: calc(100% / 3);
    right: unset;
}
.multi-view-9.view-9 {
    top: calc(calc(100% / 3) * 2);
    left: calc(calc(100% / 3) * 2);
    right: unset;
}

.mediasoup_comp {
    width: 100%;
    height: 100%;
    border: solid 1px black;
    z-index: 45;
}
.mediasoup_comp.teacher {
    border: solid 1px red;
}
img.mediasoup_comp.teacher {
    background-color: white;
}

span.mediasoup_comp.teacher, 
span.mediasoup_comp {
    position: absolute;
    width: fit-content;
    height: fit-content;
    /* right: 0; */
    top: 1px;
    left: 1px;
    font-size: 0.75rem;
    font-weight: bold;
    /* padding: 1px; */
    /* color: rgba(0, 0, 0, .4); */
    /* background: rgba(225, 205, 255, 0.6); */
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, .25);;
    padding: 0.2rem 0.15rem;
    border: none;
    text-align: center;
    vertical-align: middle;
}
span.mediasoup_comp.teacher {
    background: rgba(0, 0, 0, .4);;
}

video.mediasoup_comp {
    object-fit: fill;
    width: 100%;
    height: 100%;
}
video.mediasoup_comp.handsup {
    background-color: black;
    object-fit: fill;
}
.mobile .portrait .live-area.mediasoup video.mediasoup_comp.handsup {
    object-fit: contain;
}
@media (orientation: portrait) {
    .mobile .ios .live-area.mediasoup video.mediasoup_comp.handsup {
        object-fit: contain;
    }
}

.live-countdown {
    position: absolute;
    top: 0;
    align-self: center;
    z-index: 1000;
    font-size: 2rem;
    font-weight: bold;
    background: rgba(255, 255, 255, .4);
}

#classtalk {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 2px;
    border: 1px solid #343a40;
    background: white;
    overflow: hidden;
    opacity: 0.8;
    width: 320px;
    z-index: 50;
}
.mobile .portrait #classtalk { /* android 세로 */
    top: calc(((100vw - 8px) * 9/16) + 4px);
    margin: 0;
    width: 100% !important;
    visibility: visible !important;
}
.mobile .portrait #classtalk.connect {
    top: calc(((100vw - 8px) * 9/16) + 4px);
}
@media (orientation: portrait) { /* ios 세로 */
    .mobile .ios #classtalk {
        top: calc(((100vw - 8px) * 9/16) + 4px);
        margin: 0;
        width: 100% !important;
        visibility: visible !important;
    }
    .mobile .ios #classtalk.connect {
        top: calc(((100vw - 8px) * 9/16) + 4px);
    }
}

.live-canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 44px;
    border: 1px solid var(--h-grey);
}
.mobile .portrait .live-canvas {
    bottom: unset;
    width: 100%;
    height: calc((100vw - 8px) * 9/16);
}
@media (orientation: portrait) {
    .mobile .ios .live-canvas {
        bottom: unset;
        width: 100%;
        height: calc((100vw - 8px) * 9/16);
    }
}

.live-toolbar-area {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
    align-items: center;
}
.live-toolbar-area.bottom {
    top: unset;
    left: unset;
    right: unset;
    width: -webkit-fill-available !important;
    height: 50px;
}
.live-toolbar-area.right {
    top: unset;
    left: unset;
    bottom: unset;
    justify-content: space-around;
    padding: 0px 1px 0px 0px;
    z-index: 45;
}
.mobile .portrait .live-toolbar-area.right {
    visibility: hidden;
}
@media (orientation: portrait) {
    .mobile .ios .live-toolbar-area.right {
        visibility: hidden;
    }
}

.live-toolbar-area>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.live-toolbar-area button.hiclasstv-button {
    display: flex;
    width: 60px;
    padding: 0;
    flex-direction: column;
}

.live-toolbar-area ul {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    height: max-content;
    border-radius: .125rem;
    background-color: white;
    opacity: .8;
}
.live-toolbar-area ul>li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .25rem 0 !important;
    padding: 0 !important;
}

.live-toolbar-area>.toolbar-landscape {
    flex-direction: row;
    width: 90%;
    max-width: max-content;
    align-items: unset;
    justify-content: unset;
    visibility: visible;
}
.mobile .portrait .toolbar-landscape {
    display: none;
    visibility: hidden;
}
@media (orientation:portrait) {
    .mobile .ios .toolbar-landscape {
        display: none;
        visibility: hidden;
    }
}

.live-toolbar-area div.toolbar-portrait {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}
.mobile .portrait .toolbar-portrait {
    display: flex;
    visibility: visible;
}
@media (orientation: portrait) {
    .mobile .ios .toolbar-portrait {
        display: flex;
        visibility: visible;
    }
}

.toolbar-dropdown {
    display: inline-flex;
    vertical-align: middle;
}
.toolbar-dropdown .dropdown-menu {
    background: white;
    border: 1px solid black;
    transform: translateY(-30%);
    min-width: unset;
}
.dropdown-menu .ink-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    margin: 1px;
    cursor: pointer;
}
.dropdown-menu .ink-tool:hover {
    background-color: var(--h-grey);
}
.ink-tool .tip-tool {
    display: flex;
}

.hiclasstv-live-draw-ground {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 44px;
    /*height: calc(100% - 44px);*/
    border: 1px solid var(--h-grey);
}

.hiclasstv-live-screen {
    position: absolute;
    top: 0;
    bottom: 44px;
    right: 0;
}

.hiclasstv-my-screen {
    position: absolute;
    top: 0;
    width: 240px;
    height: 135px;
    border: 1px solid black;
    z-index: 10;
}
.mobile .hiclasstv-my-screen {
    width: 160px;
    height: 90px;
}

.small-group {
    display: flex;
    align-items: center;
}
.small-group.window {
    position: absolute;
    justify-content: center;
    width: 100%;
    bottom: 2px;
    z-index: 45;
}
.mobile .portrait .small-group.window {
    visibility: hidden !important;
}
@media (orientation: portrait) {
    .mobile .ios .small-group.window {
        visibility: hidden !important;
    }
}

.small-group.window>div {
    display: flex;
    background: white;
    border: solid 1px var(--h-deep-dark-grey);
}

.small-group.item {
    flex-direction: column;
    justify-content: space-around;
    margin: 0 .125rem 0 0;
    width: 240px;
}
.small-group.item:last-child {
    margin-right: 0;
}
.mobile .small-group.item {
    width: 160px;
}

.small-group.item img, 
.small-group.item video {
    width: 100%;
    height: 135px;
}

.mobile .small-group.item img,
.mobile .small-group.item video {
    width: 100%;
    height: 90px;
}
.mobile .small-group.item span {
    font-size: .625rem !important;
}

.hiclasstv-live-draw-ground {
    visibility: visible !important;
}

.hiclasstv-live-icon {
    width: 24px;
    height: 24px;
}
.hiclasstv-live-text {
    font-size: .6rem;
    font-weight: bold;
    color: #000;
    margin-top: .125rem;
}

.mobile .portrait img#video_comp {
    visibility: hidden !important;
}

.mobile .portrait video#video_comp {
    visibility: visible !important;
}

.mobile .portrait .hiclasstv-my-screen {
    visibility: visible;
}

.mobile .portrait .hiclasstv-live-draw-ground {
    visibility: hidden !important;
}

@media (orientation: portrait) {
    .mobile .ios img#video_comp {
        width: 160px !important;
        height: 90px !important;
        visibility: hidden !important;
    }

    .mobile .ios video#video_comp {
        visibility: visible !important;
    }

    .mobile .ios .hiclasstv-my-screen {
        visibility: visible !important;
    }

    .mobile .ios .hiclasstv-live-draw-ground {
        visibility: hidden !important;
    }
}

.hiclasstv-image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: auto;
}

.hiclasstv-classtalk-alert-action {
    border-radius: 0.8rem;
    justify-content: center;
    align-items: center;
    background-color: #6d7277;
    margin: .125rem;
}

.ct-bubble {
    display: inline-block;
    padding: .5rem;
    font-size: 0.8rem;
    width: fit-content;
    max-width: 80%;
    word-break: break-word;
    word-wrap: break-word;
    white-space: break-spaces;
    color: black;
    cursor: initial;
}
.ct-bubble>a,
.ct-bubble>div>a,
.ct-bubble>div>link {
    color: -webkit-link !important;
    text-decoration: underline !important;
}

.bubble-alert {
    font-weight: bold;
    text-align: center;
}

.bubble-msg {
    text-align: left;
    border-radius: 0.25rem;
}

.bubble-card {
    display: flex;
    flex-direction: column;
    position: relative;
    align-self: flex-start;
    align-items: flex-end;
    width: 90%;
    max-width: 90%;
    height: auto;
    margin-left: 8px;
    margin-bottom: .125rem;
    padding: .725rem .5rem .5rem;
    text-align: left;
    font-size: 0.8rem;
    word-break: break-word;
    word-wrap: break-word;
    white-space: break-spaces;
    color: black;
    border: 2px solid var(--h-deep-dark-purple);
    border-radius: 0.25rem;
}
.bubble-card~.bubble-card {
    margin-top: .25rem;
}
.bubble-card .bubble-card {
    border: 2px dashed var(--h-dark-purple-hover);
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
}

.msg-send {
    position: relative;
    margin-right: 8px;
    background-color: var(--h-yellow) !important;
}
.msg-send::after {
    content: "";
    position: absolute;
    top: 6px;
    bottom: auto;
    left: auto;
    right: -8px;
    border-width: 0px 0px 10px 8px;
    border-style: solid;
    display: block;
    width: 0;
}

.msg-rcv {
    position: relative;
    margin-left: 8px;
    max-width: calc(80% - 2rem) !important;
}
.msg-rcv::after {
    content: "";
    position: absolute;
    top: 6px;
    bottom: auto;
    left: -8px;
    border-width: 0px 8px 10px 0px;
    border-style: solid;
    display: block;
    width: 0;
}

.msg-bg-yellow {
    background-color: var(--h-yellow) !important;
}
.msg-bg-yellow::after {
    border-color: transparent var(--h-yellow) !important;
}

.msg-bg-yellow-btext {
    color: black !important;
    background-color: var(--h-yellow) !important;
}
.msg-bg-yellow-btext::after {
    border-color: transparent var(--h-yellow) !important;
}

.msg-bg-cyan {
    background-color: #99e9f2 !important;
}
.msg-bg-cyan::after {
    border-color: transparent #99e9f2 !important;
}

.msg-bg-violet {
    background-color: var(--h-purple) !important;
}
.msg-bg-violet::after {
    border-color: transparent var(--h-purple) !important;
}

.msg-bg-violet-btext {
    color: black !important;
    background-color: var(--h-purple) !important;
}
.msg-bg-violet-btext::after {
    border-color: transparent var(--h-purple) !important;
}

.msg-bg-indigo {
    background-color: #bac8ff !important;
}
.msg-bg-indigo::after {
    border-color: transparent #bac8ff !important;
}

.msg-bg-indigo-btext {
    color: black !important;
    background-color: #bac8ff !important;
}
.msg-bg-indigo-btext::after {
    border-color: transparent #bac8ff !important;
}

.msg-bg-lime {
    background-color: #c0eb75 !important;
}
.msg-bg-lime::after {
    border-color: transparent #c0eb75 !important;
}

.msg-bg-pink {
    background-color: #fcc2d7 !important;
}
.msg-bg-pink::after {
    border-color: transparent #fcc2d7 !important;
}

.msg-bg-blue {
    background-color: #74c0fc !important;
}
.msg-bg-blue::after {
    border-color: transparent #74c0fc !important;
}

.msg-bg-red {
    background-color: #ffa8a8 !important;
}
.msg-bg-red::after {
    border-color: transparent #ffa8a8 !important;
}

.msg-bg-orange {
    background-color: #ffd8a8 !important;
}
.msg-bg-orange::after {
    border-color: transparent #ffd8a8 !important;
}

.hiclasstv-classtalk-thumbnail {
    width: 90%;
    height: auto;
    border-radius: 0.5rem;
    margin: 1px 0px 0px 0px;
}

.hiclasstv-classtalk-emo {
    width: 5rem;
}
.ct-bubble.bubble-msg~.hiclasstv-classtalk-emo {
    margin-top: .25rem;
}

.classtalk-emo-button {
    display: flex;
    width: 9%;
    margin: .125rem;
    padding: .125rem;
    border: none;
    outline: none;
    transition: background .7s;
    -webkit-transition: background .7s;
}
.classtalk-emo-button:hover {
    background-color: var(--h-grey);
    opacity: 1;
    cursor: pointer;
}
.classtalk-emo-button:focus {
    outline: 0;
}

.classtalk-emo-image {
    display: flex;
    width: 100%;
}

.user-panel {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    overflow: auto;
    background-color: rgba(255, 255, 255, .7);
    border-top: 1px solid rgba(165, 165, 165, .7);
}
.user-panel>div {
    display: flex;
    padding: .5rem 0;
}
.user-panel>div~div {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: start;
    width: 100%;
    padding: .25rem;
}
.user-panel .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 25%;
    max-width: 25%;
}
.user-panel button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
}

.whisper-modal {
    width: 100%;
}
.whisper-modal .msgBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    padding: .5rem;
}
.msgBox .text {
    padding: .5rem 0 0;
}

.msgBox.answer {
    width: 95%;
    padding: 0;
    margin-bottom: .5rem;
    background-color: rgb(232, 240, 255);
}

.bubble-card>div {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.bubble-card button {
    color: white !important;
    padding: .25rem .5rem;
    margin: .5rem 0 0;
}
.bubble-card>*~button {
    margin-top: .725rem;
}

.bubble-card .image-item {
    width: 95%;
    max-width: 100%;
    height: auto;
    align-self: center;
    border-radius: 0.5rem;
    margin: 1px 0px 0px 0px;
}

.bubble-card .text-item {
    padding: .25rem 0 0;
    white-space: pre-wrap;
}

.bubble-card .audio-item {
    width: 100%;
    max-height: 2.25rem;
    align-self: center;
}

.bubble-card .video-item {
    width: 100%;
    height: auto;
    align-self: center;
}

.bubble-card iframe {
    border: none;
    text-align: center;
    align-self: center;
    width: 100%;
    height: auto;
    margin: 5px 5px;
}

.bubble-card .aniMP3-item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.aniMP3-item .img-item {
    max-width: 95%;
    align-self: center;
}
.aniMP3-item .text-item {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: .7rem;
}
.aniMP3-item .control {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    -webkit-transition: .3s;
    transition: .3s;
    cursor: pointer;
}
.aniMP3-item .control:hover {
    opacity: 0.5;
}

.bubble-card .link-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .25rem;
    margin-bottom: .25rem;
    border-radius: .25rem;
    border: 1px solid var(--h-grey);
    background: white;
    cursor: pointer;
}

.link-item .og-img {
    display: flex;
    align-items: center;
    padding: .25rem;
    width: 100%;
    min-height: 100px;
}
.link-item .og-text {
    display: grid;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: .25rem;
}
.og-img~.og-text {
    border-top: 1px solid var(--h-dark-grey-hover);
}
.og-text .og-title {
    font-size: 120%;
    font-weight: bold;
    white-space: pre-wrap;
}
.og-text .og-desc {
    width: 100%;
    font-size: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bubble-card .pronun-card {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
}
.pronun-card>button {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
    padding: .5rem .5rem !important;
}
.pronun-card .text-item {
    font-size: 1rem;
}
.pronun-card .text-item~.text-item {
    font-size: .9rem;
    padding-top: .25rem;
}
.pronun-card .score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 0.25rem;
    border: 1px solid black;
}
.pronun-card .score.score-title {
    font-weight: bold;
    margin-right: 0.25rem;
}
.pronun-card .score span {
    margin: 0.25rem 0;
    padding: 0.125rem 0.25rem;
}

.bubble-card .inkMedia-item {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-self: center;
    border: none;
    border-radius: .125rem;
    background-color: var(--h-grey);
    -webkit-transition: .3s;
    transition: .3s;
}

.inkMedia-item .draw-area {
    display: flex;
    position: relative;
    width: 100%;
    height: 9.925rem;
}
.inkMedia-item progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.2rem;
    border-radius: 0.5rem;
}
.inkMedia-item progress::-webkit-progress-bar {
    background: var(--h-dark-grey);
}
.inkMedia-item progress::-webkit-progress-value {
    background: var(--h-dark-green);
}
.inkMedia-item .control {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem !important;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
}
.inkMedia-item .control:hover {
    opacity: 0.5;
}

.quiz-card>.text-item {
    font-weight: bold;
}

.quiz-card video,
.quiz-card img,
.quiz-card audio {
    width: 100%;
}

.quiz-card button:not(.response) {
    font-weight: bold;
    font-size: 1rem;
    margin: .5rem 0 0;
    padding: .25rem 0;
}
.quiz-card .resp-progress {
  display: inline-block;
  background-color: lightgray;
  padding: .25rem 0;
  max-width: 70%;
}
.quiz-card .resp-progress~span.resp-value {
  margin-left: .25rem;
}
.quiz-card span.resp-value {
  color: var(--h-dark-grey);
}
.quiz-card .answer .resp-progress {
  background-color: var(--h-blue);
}
.quiz-card .answer .resp-value {
  font-weight: bold;
  color: var(--h-blue);
}
.quiz-card .answer-area {
    font-weight: bolder;
    color: var(--h-deep-dark-grey);
    font-size: 0.75rem;
}
.quiz-card .answer-area.input-area {
    background-color: transparent;
    font-size: 0.8rem;
}
.quiz-card .example-area {
    display: flex;
    flex-direction: column;
    margin-top: .75rem;
}
.quiz-card .example-area.image {
    flex-direction: row;
    flex-wrap: wrap;
}
.quiz-card .text-item {
    padding: unset;
}
.example-area .example-item {
    display: inline-flex;
    padding: .125rem 0;
}
.example-item *[type="item"]:not(.text-item) {
    width: 95%;
    margin-bottom: .5rem;
}
.example-item.student *[type="item"] {
    width: 92%;
}
.example-item *[type="item"]:focus {
    outline: none;
}

.response-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: .25rem 0 .5rem;
}

.quiz-card .keyPad-area {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.keyPad-area.more5 {
    flex-wrap: wrap;
    justify-content: space-between;
}
.keyPad-area button.keyPad-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .25rem;
    padding: .25rem .725rem !important;
    width: 50%;
    border-radius: .25rem;
    border: 2px solid var(--h-deep-dark-purple);
    background: var(--h-dark-purple);
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
}
.keyPad-item:first-child {
    margin-left: 0 !important;
}
.keyPad-item:last-child {
    margin-right: 0 !important;
}
.keyPad-item:hover:not(:disabled) {
    background: var(--h-dark-purple-hover);
}
.keyPad-area.more5 .keyPad-item {
    width: 30%;
    min-width: 15%;
    margin-left: 0;
}

.keyPad-item.selected {
    background: var(--h-deep-dark-purple) !important;
}
.keyPad-item.correct {
    background: var(--h-green) !important;
    border-color: var(--h-dark-green) !important;
}
.keyPad-item.wrong {
    background: rgb(230, 0, 0) !important;
    border-color: rgb(130, 0, 0) !important;
}
.keyPad-item.answer {
    background: var(--h-blue) !important;
    border-color: var(--h-dark-blue) !important;
}

.bubble-card .congrats-card {
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;
}
.congrats-card img {
    width: 100%;
    height: auto;
    margin-bottom: .5rem;
}

.bubble-card *[type="item"]~*[type="item"] {
    margin-top: .725rem !important;
}

.ct-profile-img {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    margin: 4px 1px 0px 1px;
}

.ct-profile-img.watson {
  overflow: hidden;
  border: 2px solid var(--h-purple);
  border-radius: .25rem;
  outline: none;
}

.ct-profile-nickname {
    display: inline-block;
    margin: 0px 0px 1px 8px;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem;
    font-weight: bold;
    color: #495057;
}

.hiclasstv-classtalk-menu {
    display: flex;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 50%;
    margin: 0px 0px;
    padding: .25rem;
    transition: background .3s;
    -webkit-transition: background .3s;
}
.hiclasstv-classtalk-menu:focus {
    outline: none;
}
.hiclasstv-classtalk-menu:hover {
    outline: none;
    /* background-color: rgba(63, 133, 203, 0.9) !important; */
    background-color: rgba(63, 133, 203, 0.9);
}

.hiclasstv-classtalk-menu select {
    /* background-color: rgba(63, 133, 203, 1.0) !important; */
    background-color: rgba(63, 133, 203, 1.0);
}
.hiclasstv-classtalk-menu select:hover {
    /* background-color: rgba(63, 133, 203, 0.9) !important; */
    background-color: rgba(63, 133, 203, 0.9);
}

.hiclasstv-classtalk-text {
    font-size: 8px;
    font-weight: bolder;
    color: #868e96 !important;
}

.hiclasstv-classtalk-icon {
    width: 30px;
    height: 30px;
}

.hiclasstv-classtalk-input-main {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2px;
    height: 2rem;
    max-height: 10rem;
}

.hiclasstv-classtalk-input-box {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin: 0px 2px 0px 2px;
    border: 1px solid #1098ad;
    outline: none;
}

.hiclasstv-classtalk-input {
    width: 100%;
    height: 100%;
    resize: none;
    overflow-y: hidden;
    padding: 6px 6px 2px 6px;
    font-size: 0.9rem;
    border: none;
    outline: none;
}
.hiclasstv-classtalk-input:focus {
    outline: none;
}

.hiclasstv-classtalk-button {
    display: flex;
    width: 2rem;
    min-width: 2rem;
    height: 100%;
    max-height: 2rem;
    padding: 0px;
    margin: 0px;
    outline: none;
    border: none;
    align-items: center;
    justify-content: center;
    background: white;
    transition: background .3s;
    -webkit-transition: background .3s;
}
.hiclasstv-classtalk-button:focus {
    outline: none;
}
.hiclasstv-classtalk-button:hover {
    background-color: var(--h-grey) !important;
    opacity: 0.9;
    cursor: pointer;
}

.hiclasstv-tooltip {
    position: absolute;
    min-width: min-content;
    word-wrap: break-word;
    word-break: keep-all;
    padding: 2px 8px;
    visibility: visible;
    border-radius: 0.4rem;
    background-color: black;
    opacity: 0.25;
    color: white;
    text-align: center;
    font-size: .75rem;
    font-weight: bold;
    z-index: 1;
    bottom: 2.5rem;
    right: 0;
    transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    pointer-events: none;
}
.hiclasstv-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 10px;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.ink-memo {
    width: 100% !important;
    max-width: 300px !important;
    display: flex;
    background-color: white;
}
.ink-memo.window {
    height: max-content;
    flex-direction: column !important;
    justify-content: space-around !important;
    box-shadow: 0px 0px 20px #495057;
}
.ink-memo.title {
    align-items: center;
    justify-content: space-between !important;
    padding: 4px 0px;
    background-color: #e9ecef !important;
}
.ink-memo.draw-ground {
    height: 80% !important;
    min-height: 30px;
    overflow: auto;
}
.ink-memo.canvas {
    overflow: auto;
    -webkit-user-drag: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ink-memo.ink-menu {
    width: 100%;
    justify-content: space-between !important;
    padding: 4px;
}

.ink-memo.ink-btn {
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    color: rgba(63, 133, 203, 0.9);
    justify-content: center;
    align-items: center;
}
.ink-memo.ink-btn:hover {
    opacity: 1;
    background-color: var(--h-grey);
}
.ink-memo.ink-btn:focus {
    outline: none;
}
.ink-memo.ink-btn.selected {
    color: white;
    background-color: rgba(63, 133, 203, 0.9) !important;
}
.ink-memo.ink-btn.selected:hover {
    opacity: 1;
    background-color: rgb(63, 133, 203) !important;
}
.ink-memo.ink-btn.selected:focus {
    outline: none;
}

.icon-gradation {
    animation: hiclasstv-gradation 1.5s linear alternate-reverse infinite;
    -webkit-animation: hiclasstv-gradation 1.5s linear alternate-reverse infinite;
    -moz-animation: hiclasstv-gradation 1.5s linear alternate-reverse infinite;
    -o-animation: hiclasstv-gradation 1.5s linear alternate-reverse infinite;
}

.icon-spin {
    animation: hiclasstv-spin 1.2s linear infinite;
    -webkit-animation: hiclasstv-spin 1.2s linear infinite;
    -moz-animation: hiclasstv-spin 1.2s linear infinite;
    -o-animation: hiclasstv-spin 1.2s linear infinite;
}

.menu-color-auto {
    animation: hiclasstv-color-auto 1.5s linear alternate-reverse infinite;
    -webkit-animation: hiclasstv-color-auto 1.5s linear alternate-reverse infinite;
    -moz-animation: hiclasstv-color-auto 1.5s linear alternate-reverse infinite;
    -o-animation: hiclasstv-color-auto 1.5s linear alternate-reverse infinite;
}

.background-gradation {
    background: linear-gradient(-45deg, #ee7752, #e64080, #23a6d5, #08a883);
    background-size: 400% 400%;
    animation: hiclasstv-gradation-bg 5s ease infinite;
    -webkit-animation: hiclasstv-gradation-bg 5s ease infinite;
    -moz-animation: hiclasstv-gradation-bg 5s ease infinite;
    -o-animation: hiclasstv-gradation-bg 5s ease infinite;
}

@-webkit-keyframes hiclasstv-gradation-bg {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes hiclasstv-gradation-bg {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-o-keyframes hiclasstv-gradation-bg {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes hiclasstv-gradation-bg {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes hiclasstv-spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

@-moz-keyframes hiclasstv-spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

@-o-keyframes hiclasstv-spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

@keyframes hiclasstv-spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

@-webkit-keyframes hiclasstv-gradation {
    0% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.25;
    }

    40% {
        opacity: 0.45;
    }

    60% {
        opacity: 0.65;
    }

    80% {
        opacity: 0.85;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes hiclasstv-gradation {
    0% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.25;
    }

    40% {
        opacity: 0.45;
    }

    60% {
        opacity: 0.65;
    }

    80% {
        opacity: 0.85;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes hiclasstv-gradation {
    0% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.25;
    }

    40% {
        opacity: 0.45;
    }

    60% {
        opacity: 0.65;
    }

    80% {
        opacity: 0.85;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hiclasstv-gradation {
    0% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.25;
    }

    40% {
        opacity: 0.45;
    }

    60% {
        opacity: 0.65;
    }

    80% {
        opacity: 0.85;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes hiclasstv-color-auto {
    0% {
        background-color: #ff8787;
    }

    20% {
        background-color: #e599f7;
    }

    40% {
        background-color: #66d9e8;
    }

    60% {
        background-color: #ffe066;
    }

    80% {
        background-color: #c0eb75;
    }

    100% {
        background-color: #91a7ff;
    }
}

@-moz-keyframes hiclasstv-color-auto {
    0% {
        background-color: #ff8787;
    }

    20% {
        background-color: #e599f7;
    }

    40% {
        background-color: #66d9e8;
    }

    60% {
        background-color: #ffe066;
    }

    80% {
        background-color: #c0eb75;
    }

    100% {
        background-color: #91a7ff;
    }
}

@-o-keyframes hiclasstv-color-auto {
    0% {
        background-color: #ff8787;
    }

    20% {
        background-color: #e599f7;
    }

    40% {
        background-color: #66d9e8;
    }

    60% {
        background-color: #ffe066;
    }

    80% {
        background-color: #c0eb75;
    }

    100% {
        background-color: #91a7ff;
    }
}

@keyframes hiclasstv-color-auto {
    0% {
        background-color: #ff8787;
    }

    20% {
        background-color: #e599f7;
    }

    40% {
        background-color: #66d9e8;
    }

    60% {
        background-color: #ffe066;
    }

    80% {
        background-color: #c0eb75;
    }

    100% {
        background-color: #91a7ff;
    }
}

/***********************************************************/
/****************** Screen Orientation *********************/
/***********************************************************/

@media (min-width:550px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/***********************************************************/
/************************ Ink Tool *************************/
/***********************************************************/

.color-window {
    display: none;  /* Hidden by default */
    right: 40px;
    width: 400px;
    height: 400px;
}

.color-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    grid-gap: 5px;
    padding: 15px;
}

.color-item {
    border: 1px solid black;
    width: 30px; /* Full width */
    height: 30px; /* Full height */
    background-color: white;
}

.color-foot-grid {
    display: grid;
    grid-template-columns: auto 70px 70px;
    grid-gap: 5px;
    padding: 15px;
}

.color-foot-grid>div {
    margin: auto;
}

.motal-button {
    padding: 5px 10px;
}

#color-picker-body {
    position: relative;
    left: 0;
    top: 0;
    width: 350px; /* Full width */
    height:290px; /* Full height */
    background-color: rgb(234,234,234);
    padding: 50px 25px;
    display: none;
}

.color-picker-container {
    padding: 50px 25px;
    background-color: rgb(47, 79, 79);
}

.color-picker-canvas-grid {
    display: grid;
    grid-template-columns: auto 20px 20px;
    grid-gap: 5px;
    padding: 15px;
}

.color-picker-foot-grid {
    display: grid;
    grid-template-columns: 75px 75px 75px 75px;
    grid-gap: 5px;
    padding: 15px;
}

.colorSlidecontainer {
    position: relative; 
    left: 209px;
    top: -114px;
    transform: rotate(270deg);  
    width: 234px; 
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 0px;
    background: #d3d3d3;
    outline: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 33px;
    border: 0;
    /* background: url('./components/image/toolbar/magenta_triangle.png'); */
    background: url('/images/toolbar/magenta_triangle.png');
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 35px;
    height: 33px;
    /* background: url('./components/image/toolbar/magenta_triangle.png'); */
    background: url('/images/toolbar/magenta_triangle.png');
    cursor: pointer;
}

#valueCercle {
    position: absolute;
    left: 30px;
    top: 55px;
}

.color-code-grid {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 0px;
    padding: 0px;
}

.color-code {
    font-size: 8px;
    text-align: center;
}

#color-text {
    width: 75px;
    padding: 1px 1px;
    border: 1px solid black;
    border-radius: 1px;
    text-align: center;
}

/***********************************************************/
/******************* Modal Color Button ********************/
/***********************************************************/

.modal-btn {
    --h-btn-padding-x: 1.5rem;
    --h-btn-padding-top: .625rem;
    --h-btn-padding-bottom: .5rem;
    --h-btn-font-family: var(--h-body-font-family);
    --h-btn-font-size: .75rem;
    --h-btn-font-weight: 500;
    --h-btn-line-height: 1.5;
    --h-btn-color: #212529;
    --h-btn-bg-color: transparent;
    --h-btn-border-width: 1px;
    --h-btn-border-color: transparent;
    --h-btn-border-radius: .5rem;
    --h-btn-hover-color: transparent;
    --h-btn-box-shadow: 0 4px 9px -4px rgb(0 0 0 / 35%);
    --h-btn-disabled-opacity: .625;
    --h-btn-focus-box-shadow: 0 0 0 .2rem rgba(var(--h-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--h-btn-padding-top) var(--h-btn-padding-x) var(--h-btn-padding-bottom) !important;
    font-family: var(--h-btn-font-family);
    font-size: var(--h-btn-font-size) !important;
    font-weight: var(--h-btn-font-weight) !important;
    line-height: var(--h-btn-line-height);
    color: var(--h-btn-color) !important;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    border: var(--h-btn-border-width) solid var(--h-btn-border-color);
    border-radius: var(--h-btn-border-radius) !important;
    background-color: var(--h-btn-bg-color) !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.modal-btn:hover {
    color: var(--h-btn-hover-color) !important;
    background-color: var(--h-btn-hover-bg-color) !important;
    border-color: var(--h-btn-hover-border-color) !important;
    box-shadow: var(--h-btn-focus-box-shadow) !important;
}
.modal-btn.focus,
.modal-btn:focus {
    color: var(--h-btn-hover-color) !important;
    background-color: var(--h-btn-hover-bg-color) !important;
    border-color: var(--h-btn-hover-border-color) !important;
    /* box-shadow: var(--h-btn-focus-box-shadow) !important; */
}
.modal-btn.active,
.modal-btn:active,
.modal-btn:not(:disabled):not(.disabled).active,
.modal-btn:not(:disabled):not(.disabled):active {
    color: var(--h-btn-active-color) !important;
    background-color: var(--h-btn-active-bg-color) !important;
    border-color: var(--h-btn-active-border-color) !important;
}
.modal-btn.disabled,
.modal-btn:disabled {
    opacity: var(--h-btn-disabled-opacity) !important;
    color: var(--h-btn-disabled-color) !important;
    background-color: var(--h-btn-disabled-bg-color) !important;
    border-color: var(--h-btn-disabled-border-color) !important;
}

.modal-btn-primary {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #3b71ca;
    --h-btn-border-color: #3b71ca;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #3265b9;
    --h-btn-hover-border-color: #2f5aa2;
    --h-btn-focus-shadow-rgb: 59, 113, 202;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #2d5aa5;
    --h-btn-active-border-color: #2c5598;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #3b71ca;
    --h-btn-disabled-border-color: #3b71ca;
}

.modal-btn-secondary {
    --h-btn-color: #285192;
    --h-btn-bg-color: #e3ebf7;
    --h-btn-border-color: #e3ebf7;
    --h-btn-hover-color: #285192;
    --h-btn-hover-bg-color: #dce4f0;
    --h-btn-hover-border-color: #e3ebf7;
    --h-btn-focus-shadow-rgb: 227, 235, 247;
    --h-btn-active-color: #285192;
    --h-btn-active-bg-color: #d5dde8;
    --h-btn-active-border-color: #bbcfec;
    --h-btn-disabled-color: #285192;
    --h-btn-disabled-bg-color: #e3ebf7;
    --h-btn-disabled-border-color: #e3ebf7;
}

.modal-btn-success {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #28a745;
    --h-btn-border-color: #28a745;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #218838;
    --h-btn-hover-border-color: #1e7e34;
    --h-btn-focus-shadow-rgb: 72, 180, 97;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #1e7e34;
    --h-btn-active-border-color: #1c7430;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #28a745;
    --h-btn-disabled-border-color: #28a745;
}

.modal-btn-danger {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #dc4c64;
    --h-btn-border-color: #dc4c64;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #d83752;
    --h-btn-hover-border-color: #e05e74;
    --h-btn-focus-shadow-rgb: 220, 76, 100;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #cd2844;
    --h-btn-active-border-color: #e05e74;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #dc4c64;
    --h-btn-disabled-border-color: #dc4c64;
}

.modal-btn-warning {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #e4a11b;
    --h-btn-border-color: #e4a11b;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #cd9118;
    --h-btn-hover-border-color: #e7aa32;
    --h-btn-focus-shadow-rgb: 228, 161, 27;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #b68116;
    --h-btn-active-border-color: #e7aa32;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #e4a11b;
    --h-btn-disabled-border-color: #e4a11b;
}

.modal-btn-info {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #54b4d3;
    --h-btn-border-color: #54b4d3;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #40abce;
    --h-btn-hover-border-color: #65bcd7;
    --h-btn-focus-shadow-rgb: 84, 180, 211;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #329fc2;
    --h-btn-active-border-color: #65bcd7;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #54b4d3;
    --h-btn-disabled-border-color: #54b4d3;
}

.modal-btn-light {
    --h-btn-color: #212529;
    --h-btn-bg-color: #f8f9fa;
    --h-btn-border-color: #f8f9fa;
    --h-btn-hover-color: #212529;
    --h-btn-hover-bg-color: #e2e6ea;
    --h-btn-hover-border-color: #dae0e5;
    --h-btn-focus-shadow-rgb: 216, 217, 219;
    --h-btn-active-color: #212529;
    --h-btn-active-bg-color: #dae0e5;
    --h-btn-active-border-color: #d3d9df;
    --h-btn-disabled-color: #212529;
    --h-btn-disabled-bg-color: #f8f9fa;
    --h-btn-disabled-border-color: #f8f9fa;
}

.modal-btn-link {
    --h-btn-color: #3b71ca;
    --h-btn-bg-color: transparent;
    --h-btn-border-color: transparent;
    --h-btn-hover-color: #2f5aa2;
    --h-btn-hover-bg-color: #f5f5f5;
    --h-btn-hover-border-color: transparent;
    --h-btn-focus-shadow-rgb: 0, 0, 0;
    --h-btn-active-color: #2f5aa2;
    --h-btn-active-bg-color: #f5f5f5;
    --h-btn-active-border-color: transparent;
    --h-btn-disabled-color: #3b71ca;
    --h-btn-disabled-bg-color: transparent;
    --h-btn-disabled-border-color: transparent;
}

button.btn-green {
    color: #ffffff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    /* background-color: #14a44d !important; */
    /* border-color: #14a44d !important; */
}
.btn-green:hover {
    color: #ffffff;
    background-color: #218838 !important;
    border-color: #1e7e34 !important;
    /* background-color: #118d42 !important; */
    /* border-color: #2cad5f !important; */
}
.btn-green.focus,
.btn-green:focus {
    color: #ffffff;
    background-color: #218838 !important;
    border-color: #1e7e34 !important;
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5) !important;
    /* background-color: #118d42 !important; */
    /* border-color: #2cad5f !important; */
    /* box-shadow: 0 0 0 .2rem rgba(20, 167, 77, .5) !important; */
    /* box-shadow: 0 8px 9px -4px rgb(20 164 77 / 30%), 0 4px 18px 0 rgb(20 164 77 / 20%) !important; */
}
.btn-green.active,
.btn-green:active,
.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #1e7e34 !important;
    border-color: #1c7430 !important;
    /* background-color: #0e7738 !important; */
    /* border-color: #2cad5f !important; */
}
.btn-green.disabled,
.btn-green:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    /* background-color: #14a44d !important; */
    /* border-color: #14a44d !important; */
}

button.btn-white {
    color: #212529;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
    /* color: #4f4f4f; */
    /* background-color: #fbfbfb !important; */
    /* border-color: #fbfbfb !important; */
}
.btn-white:hover {
    color: #212529;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
    /* color: #4f4f4f; */
    /* background-color: #eeeeee !important; */
    /* background-color: #fcfcfc !important; */
    /* border-color: #fbfbfb !important; */
}
.btn-white.focus,
.btn-white:focus {
    color: #212529;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5) !important;
    /* color: #4f4f4f; */
    /* background-color: #eeeeee !important; */
    /* background-color: #fcfcfc !important; */
    /* border-color: #fbfbfb !important; */
    /* box-shadow: 0 0 0 .2rem rgba(251, 251, 251, .5) !important; */
    /* box-shadow: 0 8px 9px -4px hsl(0deg 0% 98% / 30%), 0 4px 18px 0 hsl(0deg 0% 98% / 20%) !important; */
}
.btn-white.active,
.btn-white:active,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #dae0e5 !important;
    border-color: #d3d9df !important;
    /* color: #4f4f4f; */
    /* background-color: #e2e2e2 !important; */
    /* background-color: #fcfcfc !important; */
    /* border-color: #fbfbfb !important; */
}
.btn-white.disabled,
.btn-white:disabled {
    opacity: .65 !important;
    color: #212529;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
    /* color: #4f4f4f; */
    /* background-color: #fbfbfb !important; */
    /* border-color: #fbfbfb !important; */
}

button.btn-red {
    color: #ffffff;
    background-color: #dc4c64 !important;
    border-color: #dc4c64 !important;
}
.btn-red:hover {
    color: #ffffff;
    background-color: #d83752 !important;
    border-color: #e05e74 !important;
}
.btn-red.focus,
.btn-red:focus {
    color: #ffffff;
    background-color: #d83752 !important;
    border-color: #e05e74 !important;
    box-shadow: 0 0 0 .2rem rgba(220, 76, 100, .5) !important;
    /* box-shadow: 0 8px 9px -4px rgb(220 76 100 / 30%), 0 4px 18px 0 rgb(220 76 100 / 20%) !important; */
}
.btn-red.active,
.btn-red:active,
.btn-red:not(:disabled):not(.disabled).active,
.btn-red:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #cd2844 !important;
    border-color: #e05e74 !important;
}
.btn-red.disabled,
.btn-red:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #dc4c64 !important;
    border-color: #dc4c64 !important;
}

button.btn-primary-modal {
    color: #ffffff;
    background-color: #3b71ca !important;
    border-color: #3b71ca !important;
}
.btn-primary-modal:hover {
    color: #ffffff;
    background-color: #3265b9 !important;
    border-color: #2f5aa2 !important;
}
.btn-primary-modal.focus,
.btn-primary-modal:focus {
    color: #ffffff;
    background-color: #3265b9 !important;
    border-color: #2f5aa2 !important;
    box-shadow: 0 0 0 .2rem rgba(59, 113, 202, .5) !important;
    /* box-shadow: 0 8px 9px -4px rgb(59 113 202 / 30%), 0 4px 18px 0 rgb(59 113 202 / 20%) !important; */
}
.btn-primary-modal.active,
.btn-primary-modal:active,
.btn-primary-modal:not(:disabled):not(.disabled).active,
.btn-primary-modal:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #2d5aa5 !important;
    border-color: #2c5598 !important;
}
.btn-primary-modal.disabled,
.btn-primary-modal:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #3b71ca !important;
    border-color: #3b71ca !important;
}

button.btn-secondary-modal {
    color: #285192;
    background-color: #e3ebf7 !important;
    border-color: #e3ebf7 !important;
    /* box-shadow: none !important; */
}
.btn-secondary-modal:hover {
    color: #285192;
    background-color: #dce4f0 !important;
    border-color: #e3ebf7 !important;
    /* box-shadow: none !important; */
}
.btn-secondary-modal.focus,
.btn-secondary-modal:focus {
    color: #285192;
    background-color: #dce4f0 !important;
    /* background-color: #cfddf1 !important; */
    border-color: #e3ebf7 !important;
    box-shadow: 0 0 0 .2rem rgba(227, 235, 247, .5) !important;
    /* box-shadow: 0 8px 9px -4px rgb(227, 235, 247 / 30%), 0 4px 18px 0 rgb(227, 235, 247 / 20%) !important; */
}
.btn-secondary-modal.active,
.btn-secondary-modal:active,
.btn-secondary-modal:not(:disabled):not(.disabled).active,
.btn-secondary-modal:not(:disabled):not(.disabled):active {
    color: #285192;
    background-color: #d5dde8 !important;
    border-color: #bbcfec !important;
    /* box-shadow: none !important; */
}
.btn-secondary-modal.disabled,
.btn-secondary-modal:disabled {
    opacity: .625 !important;
    color: #285192;
    background-color: #e3ebf7 !important;
    border-color: #e3ebf7 !important;
}

button.btn-warning-modal {
    color: #ffffff;
    background-color: #e4a11b !important;
    border-color: #e4a11b !important;
}
.btn-warning-modal:hover {
    color: #ffffff;
    background-color: #cd9118 !important;
    border-color: #e7aa32 !important;
    /* box-shadow: none !important; */
}
.btn-warning-modal.focus,
.btn-warning-modal:focus {
    color: #ffffff;
    background-color: #cd9118 !important;
    /* background-color: #e8af3d !important; */
    border-color: #e7aa32 !important;
    box-shadow: 0 0 0 .2rem rgba(228, 161, 27, .5) !important;
    /* box-shadow: 0 0 0 .2rem rgba(194, 137, 23, .5) !important; */
    /* box-shadow: 0 8px 9px -4px rgb(228, 161, 27 / 30%), 0 4px 18px 0 rgb(228, 161, 27 / 20%) !important; */
}
.btn-warning-modal.active,
.btn-warning-modal:active,
.btn-warning-modal:not(:disabled):not(.disabled).active,
.btn-warning-modal:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #b68116 !important;
    /* background-color: #e9b449 !important; */
    border-color: #e7aa32 !important;
    /* box-shadow: none !important; */
}
.btn-warning-modal.disabled,
.btn-warning-modal:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #e4a11b !important;
    border-color: #e4a11b !important;
}

button.btn-info-modal {
    color: #ffffff;
    background-color: #54b4d3 !important;
    border-color: #54b4d3 !important;
}
.btn-info-modal:hover {
    color: #ffffff;
    background-color: #40abce !important;
    border-color: #65bcd7 !important;
    /* box-shadow: none !important; */
}
.btn-info-modal.focus,
.btn-info-modal:focus {
    color: #ffffff;
    background-color: #40abce !important;
    border-color: #65bcd7 !important;
    box-shadow: 0 0 0 .2rem rgba(84, 180, 211, .5) !important;
    /* box-shadow: 0 8px 9px -4px rgb(84, 180, 211 / 30%), 0 4px 18px 0 rgb(84, 180, 211 / 20%) !important; */
}
.btn-info-modal.active,
.btn-info-modal:active,
.btn-info-modal:not(:disabled):not(.disabled).active,
.btn-info-modal:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #329fc2 !important;
    border-color: #65bcd7 !important;
    /* box-shadow: none !important; */
}
.btn-info-modal.disabled,
.btn-info-modal:disabled {
    opacity: .625 !important;
    color: #ffffff;
    background-color: #54b4d3 !important;
    border-color: #54b4d3 !important;
}

button.btn-link-modal {
    color: #3b71ca;
    text-decoration: none;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.btn-link-modal:hover {
    color: #2f5aa2;
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.btn-link-modal.focus,
.btn-link-modal:focus {
    color: #2f5aa2;
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.btn-link-modal.active,
.btn-link-modal:active,
.btn-link-modal:not(:disabled):not(.disabled).active,
.btn-link-modal:not(:disabled):not(.disabled):active {
    color: #2f5aa2;
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.btn-link-modal.disabled,
.btn-link-modal:disabled {
    opacity: .625 !important;
    color: #3b71ca;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

/***********************************************************/
/*********************** Modal Input ***********************/
/***********************************************************/

.quiz-modal .input-group>.form-control,
.quiz-modal .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.quiz-modal .input-group>.form-control:not(:first-child) {
    margin-left: 1px;
}

.quiz-modal .input-group>.custom-label {
    height: calc(1.75rem + 0.33rem + 2px);
}

.custom-label {
    display: flex;
    align-items: center;
    padding: 0.26rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 0 !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0.25rem;
}

.quiz-modal .input-group>.input-group-text,
.quiz-modal .input-group>.file-input-label>.input-group-text,
.quiz-modal .input-group>.data-area>.input-group-text,
.quiz-modal .input-group>.data-area>.file-input-label>.input-group-text {
    padding: .26rem .75rem;
    min-width: calc(3.58rem + 2px);
    line-height: 1.6;
    justify-content: flex-end;
    font-size: .85rem;
    color: #4f4f4f;
    height: calc(2.08rem + 2px);
    background-color: transparent;
    border-radius: 0rem .25rem;
}
.quiz-modal .input-group>.file-input-label>.input-group-text,
.quiz-modal .input-group>.data-area>.input-group-text,
.quiz-modal .input-group>.data-area>.file-input-label>.input-group-text {
    min-width: fit-content;
}
.example-area[type="example-data"]>.input-group>.input-group-text {
    min-width: 2.4rem;
}
.quiz-modal .input-group>.form-control {
    min-height: calc(2.08rem + 2px);
    height: calc(2.08rem + 2px);
    padding: .27rem .75rem;
    margin: 0rem;
    line-height: 1.6;
    /* color: #4f4f4f; */
    color: var(--h-deep-dark-grey);
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: all .2s linear;
}
.quiz-modal .input-group>.form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.quiz-modal .select-wrapper>.form-select,
.quiz-modal .input-group>.select-wrapper>.form-select {
    display: block;
    width: 100%;
    min-height: calc(2.08rem + 2px);
    height: calc(2.08rem + 2px);
    padding: .26rem 2.75rem .26rem .75rem;
    margin: 0rem 0rem 0rem 1px;
    font-size: .85rem;
    font-weight: 300;
    line-height: 1.6;
    /* color: #4f4f4f; */
    color: var(--h-deep-dark-grey);
    background-color: #ffffff;
    background-image: var(--h-icon-arrow);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #bdbdbd;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.quiz-modal .select-wrapper>.form-select:focus,
.quiz-modal .input-group>.select-wrapper>.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}
.quiz-modal .select-wrapper>.form-select:focus-visible,
.quiz-modal .input-group>.select-wrapper>.form-select:focus-visible {
    outline-offset: 0px;
}
.form-select option {
    font-size: .8rem;
}

.quiz-modal .input-group>.form-check-input,
.quiz-modal .input-group>.input-group-text>.form-check-input {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: .875rem;
    height: .875rem;
    margin: 0rem 0rem 0rem 1px;
    background-color: transparent;
    border: 1px solid #6e6e6e;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #ffffff;
    outline: none;
    align-self: center;
}
.quiz-modal .input-group>.form-check-input:valid[type=radio]:checked,
.quiz-modal .input-group>.input-group-text>.form-check-input:valid[type=radio]:checked {
    border-color: #007bff;
    background-color: #007bff;
}
.quiz-modal .input-group>.form-check-input~.form-check-input {
    margin-left: 2.5rem;
}
.question-area .input-group>.form-check-input~.form-check-input,
.example-area[type="example-data"] .input-group>.form-check-input~.form-check-input {
    margin-left: .5rem;
}
.quiz-modal .input-group>.form-check-label {
    display: flex;
    align-items: center;
    padding: .26rem .75rem;
    line-height: 1.6;
}
.question-area .input-group>.form-check-label,
.example-area[type="example-data"] .input-group>.form-check-label {
    padding: .26rem .65rem;
    font-size: .8rem;
    color: var(--h-deep-dark-grey);
}

.quiz-modal .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.quiz-modal .modal-sm {
    max-width: 300px;
}
.quiz-modal .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
}
.quiz-modal .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 3.5rem);
    content: "";
}

/***********************************************************/
/*********************** Quiz Modal ************************/
/***********************************************************/

.quiz-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* min-height: 100%; */
    height: 100%;
    overflow: auto;
    background-color: white !important;
}
.quiz-modal>.title-area {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    margin-top: 1rem;
}
.quiz-modal span.quiz-title {
    color: var(--h-blue);
    font-size: 1.5rem;
    font-weight: bold;
    align-self: center;
    padding: 0rem .25rem;
    margin: .5rem;
}

.content-area,
.quiz-modal>.content-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: .5rem 0rem;
    margin: .5rem 0rem;
}
.content-area hr {
    margin-top: .75rem;
    margin-bottom: .75rem;
    width: 100%;
    border-top: 1px dotted #bdbdbd;
}
.content-area .hiclasstv-coral-text {
    color: var(--h-coral) !important;
}
.content-area .file-input-label {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    width: 1%;
    min-height: calc(2.08rem + 2px);
    height: calc(2.08rem + 2px);
    margin-bottom: 0rem;
    background-color: transparent;
    align-items: center;
}
.content-area>.alert-area {
    width: 85%;
    margin-top: .5rem;
    margin-bottom: .75rem;
}
.content-area .minus-btn-area {
    display: flex;
    padding: 0rem .5rem .5rem;
    font-weight: 400;
}
.content-area .add-btn-area {
    display: flex;
    width: 100%;
    margin: .5rem 0rem .25rem;
}
.content-area .add-btn-area>.hiclasstv-btn-transparent {
    display: flex;
    align-items: center;
    line-height: normal;
}

.quiz-modal>.content-area>.question-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem 1.25rem .5rem .5rem;
    align-items: center;
    justify-content: center;
}
.question-area hr {
    width: calc(100% - 1.25rem - .5rem - 1.25rem - .5rem);
    margin-left: 3.5rem;
}
.question-area>.data-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.question-area>.data-area>.input-group>.input-group-text+.input-group-text {
    min-width: fit-content;
    padding-left: .5rem;
    padding-right: .5rem;
}
.question-area>.data-area>.input-group>.input-group-text:first-child,
.question-area>.data-area>.input-group>.input-group-text:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}
.question-area>.data-area>.input-group>.form-control+.input-group-text {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.question-area>.data-area>.input-group>.input-group-text:first-child+.file-input-label {
    padding-left: .5rem;
    border-left: 1px solid #bdbdbd;
}
.question-area>.data-area>.input-group>.file-input-label {
    padding-right: .5rem;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.question-area>.data-area>.input-group>.select-wrapper {
    flex-grow: 0 !important;
}
.question-area>.data-area>.input-group>.select-wrapper>.form-select {
    width: fit-content;
}
.question-area>.add-btn-area {
    padding-left: 3.875rem;
}

.quiz-modal>.content-area>.answer-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .25rem 1.25rem .25rem .5rem;
    align-items: center;
    justify-content: center;
}
.quiz-modal>.content-area>.answer-area[type="answer-data"] {
    padding-left: 4.625rem;
}

.quiz-modal>.content-area>.example-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .25rem 1.25rem .25rem calc(.5rem + 3.58rem + 2px);
    align-items: center;
    justify-content: center;
}
.quiz-modal>.content-area>.example-area[type="example-data"] {
    padding: .25rem 1.25rem .25rem calc(.5rem + 3.58rem - 2.4rem + 2px);
}
.example-area[type="example-data"]>.add-btn-area {
    padding-left: 2.4rem;
}
.example-area>.data-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text {
    min-width: fit-content;
    color: #9e9e9e;
    border: 1px solid #bdbdbd;
}
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:first-child,
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:first-child+.input-group-text {
    padding-left: .5rem;
    padding-right: .5rem;
}
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:first-child {
    border-right: none;
    border-bottom-right-radius: 0;
}
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:not(:first-child) {
    border-left: none;
}
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:first-child,
.example-area[type="example-data"]>.data-area>.input-group>.input-group-text:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}
.example-area[type="example-data"]>.data-area>.input-group>.form-control+.input-group-text {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.example-area[type="example-data"]>.data-area>.input-group>.form-control {
    transition: box-shadow .2s linear;
}
.example-area[type="example-data"]>.data-area>.input-group>.form-control:not(:first-child) {
    border-left: none;
    border-right: none;
    margin-left: 0;
}
.example-area[type="example-data"]>.data-area>.input-group>.file-input-label {
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
}
.example-area[type="example-data"]>.data-area>.input-group>.select-wrapper {
    flex-grow: 0 !important;
}
.example-area[type="example-data"]>.data-area>.input-group>.select-wrapper>.form-select {
    width: fit-content;
}
.quiz-modal button.modal-btn-success {
    --h-btn-color: #ffffff;
    --h-btn-bg-color: #198754;
    --h-btn-border-color: #198754;
    --h-btn-hover-color: #ffffff;
    --h-btn-hover-bg-color: #157347;
    --h-btn-hover-border-color: #146c43;
    /* --h-btn-focus-shadow-rgb: 60, 153, 110; */
    --h-btn-focus-shadow-rgb: 20 164 77;
    --h-btn-active-color: #ffffff;
    --h-btn-active-bg-color: #146c43;
    --h-btn-active-border-color: #13653f;
    --h-btn-disabled-color: #ffffff;
    --h-btn-disabled-bg-color: #198754;
    --h-btn-disabled-border-color: #198754;
    --h-btn-focus-box-shadow: 0 8px 9px -4px rgb(var(--h-btn-focus-shadow-rgb) / 30%), 0 4px 18px 0 rgb(var(--h-btn-focus-shadow-rgb) / 20%);
}

.robotRemoconMainView {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.robotRemoconMainBase {
    display: flex;
    justify-content: space-between;
}

.robotButtonMainBase {
    display: flex;
    justify-content: space-around;
}

.robotRemoconStick {
    position: relative;
    left: 32px;
    top: -98px;
}

.robotRemocon {
    padding: 50px;
}